import { DefaultDataServiceConfig } from '@ngrx/data';
import { default as config } from 'src/assets/config/config.json';

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: config.apiUrl+'/api',
  trailingSlashEndpoints: false,
  entityHttpResourceUrls: {
    PublicationState: {
      entityResourceUrl: config.apiUrl+'/api'+'/states/',
      collectionResourceUrl: config.apiUrl+'/api'+'/states'
    }
  },
};
