import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import { getAppUserRoles } from 'src/app/state/app-user-state/app-user.selectors';
import { NotifyService } from 'src/app/services/notify.service';

@Injectable()
export class AuthGuardService  {
  private appUserRoles$: Observable<string[]>;

  constructor(
    private portalUserState: Store<AppUserState>,
    private notifyService: NotifyService,
  ) {
    this.appUserRoles$ = this.portalUserState.pipe(select(getAppUserRoles));
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.appUserRoles$.pipe(map((roles) => {
      if (next.data['roles'].some((role: string) => roles.includes(role))) {
        return true;
      }

      if (roles.length > 0) {
        this.notifyService.toast('error','Permission denied');
      }

      return false;
    }));
  }
}
