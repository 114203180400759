import { createAction, props } from '@ngrx/store';
import { Publication } from 'src/app/entities/publication.entity';

export const loadPublicationDetails = createAction(
  '[PublicationDetail] Load PublicationDetails'
);

export const setPublicationToEdit = createAction(
  '[PublicationDetail] Set Publication to edit',
  props<{publicationToEdit: Publication}>()
);

export const setPublicationDirty = createAction(
  '[PublicationDetail] Set if publication data was changed',
  props<{ isDirty: boolean }>()
);

export const setPublicationSaving = createAction(
  '[PublicationDetail] Set Publication saving',
  props<{ saving: boolean }>()
);



