<div class="container align-middle justify-center mt-4">
  <form [formGroup]="formGroup" class="mx-auto max-w-max min-w-full">
    <div class="w-full">
      <h1 class="text-dguv-blue uppercase">Status "{{stateToEdit?.name}}" Tasks bearbeiten</h1>

      <div class="flex w-full">
        <div class="mr-2.5 w-full">
          <mat-card>
              <mat-card-header>
                <mat-card-title>Notiz anlegen</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <section >
                  <ng-container>
                    <mat-checkbox [(ngModel)]="writeNoteSelected" [ngModelOptions]="{standalone: true}"
                                  (change)="writeNoteTaskToggle($event)">Aktiv</mat-checkbox>
                  </ng-container>
                </section>
              </mat-card-content>
          </mat-card>
        </div>
        <div class="mr-2.5 w-full">
          <mat-card class="dark-form-field">
              <mat-card-header>
               <mat-card-title>E-Mail client öffnen</mat-card-title>
              </mat-card-header>
              <mat-card-content>
              <section >
                <ng-container>
                  <mat-checkbox [(ngModel)]="renderMailSelected" [ngModelOptions]="{standalone: true}"
                                (change)="renderMailTaskToggle($event)">Aktiv</mat-checkbox>
                </ng-container>
              </section>
                <div class="m-2 w-full">
                  <mat-form-field class="w-full" >
                    <mat-label>Template Datei</mat-label>
                    <input matInput [formControl]="formGroup.controls.tasks.controls.renderMail.controls.config.controls.templateFile">
                  </mat-form-field>
                </div>
                <div class="m-2 w-full">
                  <mat-form-field class="w-full">
                    <mat-label>Empfänger</mat-label>
                    <input matInput [formControl]="formGroup.controls.tasks.controls.renderMail.controls.config.controls.receiver">
                  </mat-form-field>
                </div>
                <div class="m-2 w-full">
                  <mat-form-field class="w-full">
                    <mat-label>Betreff</mat-label>
                    <input matInput [formControl]="formGroup.controls.tasks.controls.renderMail.controls.config.controls.subject">
                  </mat-form-field>
                </div>
              </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="flex">
        <div>
          <button mat-raised-button color="primary" (click)="save()">Speichern</button>
          <a mat-raised-button routerLink="/admin/state-tasks">Abbrechen</a>
        </div>
      </div>
    </div>
  </form>
</div>
