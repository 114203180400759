import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {

  private toolsVcr!: ViewContainerRef;

  constructor() { }

  setContentVcr(viewContainerRef: ViewContainerRef) {
    this.toolsVcr = viewContainerRef;
  }

  setTools(template: TemplateRef<any>) {
    this.toolsVcr.clear();
    this.toolsVcr.createEmbeddedView(template);
  }
}
