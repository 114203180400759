<div class="container align-middle justify-center mt-4">
  <form [formGroup]="agencyFormGroup" class="mx-auto max-w-max">
    <div>
      <h1 *ngIf="editMode==='add'" class="text-dguv-blue uppercase">Neue Agentur hinzufügen</h1>
      <h1 *ngIf="editMode==='edit'" class="text-dguv-blue uppercase">Agentur bearbeiten</h1>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Agentur Name</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.name">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <mat-form-field >
            <mat-label>Kontakt Anrede</mat-label>
<!--            <input matInput [formControl]="agencyFormGroup.controls.salutation">-->
            <mat-select [formControl]="agencyFormGroup.controls.salutation" [aria-label]="'Anrede'">
              <mat-option>--</mat-option>
              <mat-option [value]="'Frau'">Frau</mat-option>
              <mat-option [value]="'Herr'">Herr</mat-option>
              <mat-option [value]="'Divers'">Divers</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Kontakt Vorname</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.firstName">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <mat-form-field >
            <mat-label>Kontakt Nachname</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.lastName">
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Straße und Hausnummer</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.street">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <mat-form-field >
            <mat-label>PLZ</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.zipCode">
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Ort</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.city">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <mat-form-field >
            <mat-label>Kontakt E-Mail</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.email">
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Kontakt Telefon</mat-label>
            <input matInput [formControl]="agencyFormGroup.controls.phoneNumber">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <section class="grid grid-cols-3" [formGroup]="agencyTypeFormGroup">
            <ng-container formGroupName="agencyTypeGroup" *ngFor="let agencyType of agencyTypeFormGroup.get('agencyTypeGroup')?.value||''|keyvalue">
              <div>
                <mat-checkbox [formControlName]="agencyType.key"  [checked]="agencyType.value">{{agencyType.key}}</mat-checkbox>
              </div>
            </ng-container>
<!--            <p><mat-checkbox formControlName="mushroom">Mushroom</mat-checkbox></p>-->
          </section>
        </div>
      </div>
      <div class="flex">
        <div>
          <button mat-raised-button color="primary" (click)="save()">Speichern</button>
        </div>
      </div>
    </div>
  </form>
</div>
