<mat-card>
  <mat-card-header><h2>Export der aktuellen Liste der Publikationen</h2></mat-card-header>
  @if(!this.fileName ){
    <mat-card-content>
      <button mat-stroked-button color="primary" (click)="triggerExport()">Start Export der Publikations-Liste
        <mat-icon>download_for_offline</mat-icon>
      </button>
      @if(triggerStart){
        <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
      }

      @if(exportTriggered){
        <div><h3>Export gestartet</h3> <br>Sie werden per E-Mail benachrichtigt, wenn der Export als Download bereitsteht.</div>
      }
    </mat-card-content>
  } @else {
    <mat-card-content>
      <h3>Download </h3>
      <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
  }
</mat-card>
