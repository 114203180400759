<mat-toolbar color="primary" style="min-height: 70px;">

  <button mat-icon-button (click)="sidenav.toggle()" class="example-icon"
          aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="flex ml-2 block">
    <a [routerLink]=" '/list'">
     <img alt="dguv logo" ngSrc="assets/img/Logo_DGUV_weiss.svg" width="96" height="48">
    </a>
  </span>

  <span class="spacer"></span>

  <div class="pt-5 mx-2.5 w-1/2">
    <app-quick-search (dataMeta)="setSearchMetaData($event)"
                      (result)="setSearchResultData($event)"
                      [results]="listViewService.itemsPerPage"
    >
    </app-quick-search>
  </div>
  <ng-container *ngIf="currentPath.includes('publication')">
    <app-paginator class="block"
                   [pageSize]="1"
                   [length]="publicationCount"
                    (selectedPublication)="onPublicationSelected($event)"
                   aria-label="Select page">
    </app-paginator>
  </ng-container>
  <span class="spacer"></span>
  <button mat-icon-button (click)="logout()">
    <mat-icon>logout</mat-icon>
  </button>
  <div>
    <span *ngIf="(appUser$|async) as appUser">
      <h4 class="text-base">{{appUser.username}}</h4>
    </span>
  </div>
</mat-toolbar>
