import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Agency } from 'src/app/entities/agency.entity';

export interface DeleteAgencyDialogData {
  agency: Agency
}

@Component({
  selector: 'app-delete-agency-dialog',
  templateUrl: './delete-agency-dialog.component.html',
})
export class DeleteAgencyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteAgencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteAgencyDialogData) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
