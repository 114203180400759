import { Action, createReducer, on } from '@ngrx/store';
import { Agency } from 'src/app/entities/agency.entity';
import * as AgencyAdminActions from 'src/app/modules/administration/state/agency-admin.actions';

export const agencyAdminFeatureKey = 'agencyAdmin';

export interface AgencyAdminState {
  agencyToEdit: Agency
}

export const initialState: AgencyAdminState = {
  agencyToEdit: {} as Agency

};

export const agencyAdminReducer = createReducer(
  initialState,
  on(AgencyAdminActions.setAgencyToEdit, (state, agencyToEdit) => {
    return {
      ...state,
      agencyToEdit: agencyToEdit.agencyToEdit
    }
  })
);
