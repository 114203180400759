import { APP_INITIALIZER, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RestrictedToDirective } from 'src/app/directive/restricted-to.directive';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { HttpInterceptorService } from 'src/app/interceptors/http.interceptor';
import { ConfigService } from 'src/app/services/config.service';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { NotifyService } from 'src/app/services/notify.service';
import { DefaultDataServiceConfig, HttpUrlGenerator } from '@ngrx/data';
import { defaultDataServiceConfig } from 'src/app/state/config/data-service-config';
import { PluralHttpUrlGenerator } from 'src/app/services/PluralHttpUrlGenerator';
import { UrlInterceptorService } from 'src/app/interceptors/url-interceptor.service';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CustomDateAdapter } from 'src/app/adapter/custom-date-adapter';
import { SyncAllPublicationButtonComponent } from './components/sync-all-publication-button/sync-all-publication-button.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  PublicationDetailDialogComponent
} from 'src/app/dialogs/publication-detail-dialog/publication-detail-dialog.component';
import { CachingInterceptor } from 'src/app/interceptors/caching.interceptor';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
export const DATE_PIPE_DEFAULT_TIMEZONE = new InjectionToken<string>('UTC-9');
export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig('./assets/config/config.json');
};

@NgModule({
  declarations: [
    AppComponent,
    RestrictedToDirective,
    SyncAllPublicationButtonComponent,
    PublicationDetailDialogComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
  ],
  providers: [
    AuthGuardService,
    DataService,
    NotifyService,
    { provide: HttpUrlGenerator, useClass: PluralHttpUrlGenerator },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {} },
    { provide: DATE_PIPE_DEFAULT_TIMEZONE, useValue: 'UTC' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
