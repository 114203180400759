import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from 'src/app/modules/material.shared.module';
import { FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { RouterLink } from '@angular/router';
import { NgxPrintModule } from 'ngx-print';
import { DetailSearchComponent } from 'src/app/modules/detail-search/components/detail-search/detail-search.component';
import { PublicationDetailModule } from 'src/app/modules/publication-detail/publication-detail.module';

@NgModule({
    declarations: [
        DetailSearchComponent,
    ],
    exports: [
        DetailSearchComponent
    ],
    imports: [
        CommonModule,
        MaterialSharedModule,
        FormsModule,
        A11yModule,
        RouterLink,
        NgxPrintModule,
        PublicationDetailModule
    ]
})
export class DetailSearchModule { }
