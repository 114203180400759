import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { select, Store } from '@ngrx/store';
import { AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import { CookieService } from 'ngx-cookie-service';
import { login, setToken } from 'src/app/state/app-user-state/app-user.actions';
import { AuthToken } from 'src/app/models/auth-token.model';
import { ConfigService } from 'src/app/services/config.service';
import { AppUser } from 'src/app/models/app-user.model';
import { NotifyService } from 'src/app/services/notify.service';
import { Router } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { getLogoutState } from 'src/app/state/app-user-state/app-user.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  private apiHost = '';
  private username: string|null = '';
  private tokenInterval = 3600;
  private tokenCycle: ReturnType<typeof setInterval>  | undefined;

  private appUser: AppUser = {} as AppUser;

  private cookieValidated = false;

  constructor(private fb: FormBuilder,
              private dataService: DataService,
              private appUserState: Store<AppUserState>,
              private cookieService: CookieService,
              private configService: ConfigService,
              private notifyService: NotifyService,
              private router: Router,
              ) { }

  public ngOnInit(): void {
    this.tokenInterval = this.configService.config.authentication.token.lifeCycle;
    this.apiHost = this.configService.config.apiUrl;

    this.checkUserCookie();

    this.appUserState.pipe(select(getLogoutState)).subscribe((logout) => {
      if(logout){
        this.logout();
      }
    })
  }


  public submit() {
    if (this.loginForm.valid) {
      this.dataService
        .makePostCall<AuthToken>(this.configService.config.apiUrl+this.configService.config.authentication.api, this.loginForm.getRawValue())
        .pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse ) {
              this.notifyService.toast('error', 'Anmeldung fehlgeschlagen. Bitte prüfen Sie Ihre Anmeldedaten.');
              return EMPTY;
            }
            throw error;
          })
        )
        .subscribe((response) => {
          this.username = this.loginForm.controls.username.getRawValue();

          const appUser = {
            userId: '',
            username: this.username,
            token: response.token,
            refreshToken: response.refresh_token
          } as AppUser;
          this.appUserState.dispatch(login(appUser));
          this.setUserDataToken(appUser);
          this.startTokenRefreshCycle(response.refresh_token);
          this.router.navigate(['list']);
          this.notifyService.toast('success', 'Logged in');
        });
    }
  }

  private clearTokenRefreshCycle() {
    clearInterval(this.tokenCycle);
  }

  private setUserDataToken(appUser: AppUser) {

    const stringify = JSON.stringify(appUser);
    const serial = btoa(stringify);

    this.cookieService.set('userData', serial, 9999, '/');
  }

  startTokenRefreshCycle(refreshToken: string) {
    if (typeof this.tokenCycle === 'undefined') {
      this.tokenCycle = setInterval(() => {
        this.refreshToken(refreshToken);
      }, this.tokenInterval)
    }
  }

  refreshToken(refreshToken: string) {

    const refreshData = {
      refresh_token: refreshToken
    }
    return this.dataService.makePostCall<AuthToken>(this.apiHost+this.configService.config.authentication.tokeRefreshUrl, refreshData)
      .subscribe( {
        next: (response) => {
          this.appUserState.dispatch(setToken({token: response.token}))
          this.appUser.token = response.token;
          this.appUser.refreshToken = response.refresh_token;
          this.setUserDataToken(this.appUser);

          if(!this.cookieValidated){
            this.cookieValidated = true;
            this.appUserState.dispatch(login(this.appUser));
            this.router.navigate(['list']);
          }
        },
        error: () => {
          this.logout();
          this.notifyService.toast('info', 'You have been logged out.');
        }

      });
  }

  private checkUserCookie() {
    const appUser = this.getAppUserFromCookie();

    if (appUser !== null) {
        this.appUser = appUser;
        this.refreshToken(appUser.refreshToken);

        this.startTokenRefreshCycle(appUser.refreshToken);
    }
  }

  private getAppUserFromCookie(): AppUser|null {
    const userData = this.cookieService.get('userData') ;

    if ((typeof userData !== 'undefined') && (userData.length > 10)) {
      const decoded = atob(userData);

      const appUserCookie: AppUser = JSON.parse(decoded);

      if ((typeof appUserCookie !== 'undefined')) {

        const appUser = {
          userId: '',
          username: appUserCookie.username,
          token: appUserCookie.token,
          refreshToken: appUserCookie.refreshToken
        } as AppUser;

        return appUser
      }
    }

    return null;
  }

  private logout(){
      this.cookieService.delete('userData');
      this.unauthorizedRedirect();
      this.clearTokenRefreshCycle();
  }

  unauthorizedRedirect() {
      this.router.navigate(['/']);
  }
}
