import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Agency } from 'src/app/entities/agency.entity';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgencyService extends EntityCollectionServiceBase<Agency> {
  private cache$: Observable<Agency[]> | undefined;

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Agency', serviceElementsFactory);
  }

  getAgenciesWithQuery(query: string): Observable<Agency[]> {
    // Überprüfen, ob ein Cache existiert und ob die Anfrage mit dem Cache übereinstimmt
    if (!this.cache$) {
      this.cache$ = super.getWithQuery(query).pipe(
        shareReplay(1),
        catchError(err => {
          // Cache leeren, wenn ein Fehler auftritt
          this.cache$ = undefined;
          return of([]); // Leeres Array zurückgeben oder Fehlerbehandlung durchführen
        })
      );
    }
    return this.cache$;
  }

  override clearCache() {
    this.cache$ = undefined;
  }
}
