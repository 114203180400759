import {Component, EventEmitter, Injectable, Output} from '@angular/core';
import { Store } from '@ngrx/store';
import { PublicationDetailState } from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.reducer';
import { getPublicationToEdit } from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.selectors';
import { Publication } from 'src/app/entities/publication.entity';
import {NotifyService} from "../../services/notify.service";
import {DataService} from "../../services/data.service";
import {ConfigService} from "../../services/config.service";
import {EntityCollectionService, EntityServices} from "@ngrx/data";

export interface ShopApiResponse {
  success: boolean
  statusCode: number
  message: string
}

@Component({
  selector: 'app-refresh-publication-button',
  templateUrl: './refresh-publication-button.component.html',
  styleUrls: ['./refresh-publication-button.component.scss']
})

@Injectable()
export class RefreshPublicationButtonComponent {
  private publication?: Publication;
  public publicationService: EntityCollectionService<Publication>;

  @Output() refreshPublication = new EventEmitter<Publication>();
  public syncing = false;

  constructor(private store: Store<PublicationDetailState>,
              private notifyService: NotifyService,
              private dataService: DataService,
              private configService: ConfigService,
              private entityServices: EntityServices
  ) {
    this.publicationService =  this.entityServices.getEntityCollectionService('Publication');
  }

  ngOnInit(): void {
    this.store.select(getPublicationToEdit).subscribe(publication => {
      this.publication = publication;
    })
  }

  onClick(): void {
    this.syncing = true;
    if (this.publication == undefined) {
      this.showErrorMessage({'message':'Keine Publikation geladen'} as ShopApiResponse);

      return;
    }

    if (this.publication.shopwareArticleId == undefined) {
      this.showErrorMessage({'message':'Keine Shopware Artikel ID gefunden'} as ShopApiResponse);

      return;
    }

    let url = this.configService.config.apiUrl + this.configService.config.publicationRefreshUrl + '/' + this.publication.shopwareArticleId
    let request = this.dataService.makeGetCall<ShopApiResponse>(url);
    request.subscribe(
      data => {
        if (data.success) {
          // request frontend to refresh publication data:
          let publication$ = this.publicationService.getByKey(this.publication?.id);
          publication$.subscribe((publication) => {
            this.notifyService.toast('success', 'Publikation wurden erfolgreich aktualisiert');
            this.emitRefreshEvent(publication);

            this.syncing = false;
          })
        } else {
          this.showErrorMessage(data);
        }
      }
    );
  }

  private showErrorMessage(apiResponse: ShopApiResponse): void {
    this.syncing = false;

    if(apiResponse.message === 'Article not found in Shop'){
      apiResponse.message = 'Artikel nicht in Shop DB gefunden. Bitte führen sie einen vollständigem Sync aus'
    }
    this.notifyService.toast('error',
      'Beim Aktualisieren der Publikation ist ein Fehler aufgetreten: '+apiResponse.message
    );
  }

  private emitRefreshEvent(publication: Publication){
    this.refreshPublication?.emit(publication)
  }
}
