import { Pipe, PipeTransform } from '@angular/core';
import { NoteService } from 'src/app/entities/services/note.service';
import { Note } from 'src/app/entities/note.entity';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'latestPublicationNote'
})
export class LatestPublicationNotePipe implements PipeTransform {

  constructor(private noteService: NoteService) {
  }
  transform(publicationId: number ): Observable<Note> {

      return this.noteService.getLatestNoteOfPublication(publicationId)

  }

}
