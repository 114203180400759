import { Injectable } from '@angular/core';
import { PropertySearch } from 'src/app/models/propertySearch.model';

@Injectable({
  providedIn: 'root'
})
export class FilterBuilderService {
  public buildSearchUrlExtension(propertyFilter: PropertySearch[]): string {
    let searchUrlExtension = '';
    propertyFilter.forEach((property) => {
      if(property.property === 'deadline'){
        const [day, month, year] = property.search.split('/');
        const date = new Date([month, day, year].join('/')).toUTCString();
        searchUrlExtension += `&${property.property}[${property.searchType}]=${date}`;
      } else {
        searchUrlExtension += `&${property.property}=${property.search}`;
      }
    });

    return searchUrlExtension;
  }
}
