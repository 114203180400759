import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AgencyAdminState } from 'src/app/modules/administration/state/agency-admin.reducer';
import { Agency } from 'src/app/entities/agency.entity';
import { getAgencyToEdit } from 'src/app/modules/administration/state/agency-admin.selectors';
import { NavigationEnd, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { AgencyType } from 'src/app/entities/agency-type.entity';
import { catchError, EMPTY, Observable, of } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-agency-admin-form',
  templateUrl: './agency-admin-form.component.html',
  styleUrls: ['./agency-admin-form.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyAdminFormComponent implements OnInit{
  public agencyService: EntityCollectionService<Agency>;
  public agencyToEdit!: Agency;
  public editMode: 'add'|'edit' | undefined;

  public agencyFormGroup = this.fb.group({
    isActive: [''],
    name: [''],
    salutation: [''],
    firstName: [''],
    lastName: [''],
    email: [''],
    street: [''],
    zipCode: [''],
    city: [''],
    phoneNumber: [''],
    agencyTypes: [['']],

  });

  public agencyTypeFormGroup = this.fb.group({
    agencyTypes: new FormArray<any>([]),
    agencyTypeGroup: this.fb.group({})
  });

  public agencyTypeService: EntityCollectionService<AgencyType>;
  public agencyTypes$: Observable<AgencyType[]>;

  public agencyTypes: AgencyType[] = [];

  constructor(
    private agencyAdminState: Store<AgencyAdminState>,
    private router: Router,
    private fb: FormBuilder,
    private entityServices: EntityServices,
    private notifyService: NotifyService,
  ) {
    this.agencyService = this.entityServices.getEntityCollectionService('Agency');
    this.agencyTypeService = this.entityServices.getEntityCollectionService('AgencyType');
    this.agencyTypeService.getAll();
    this.agencyTypes$ = this.agencyTypeService.entities$;

    router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        this.determineEditMode(event)
      }
    });

    this.agencyTypeService.entities$.subscribe((agencyTypes) => {

      this.createAgencyTypeFormGroup(agencyTypes);
    })
  }

  private determineEditMode(event: NavigationEnd){
    const editMode = event.url.split("/").pop();
    if(editMode === 'edit'){
      this.editMode = 'edit'
    } else {
      this.editMode = 'add'
    }
  }

  private createAgencyTypeFormGroup(agencyTypes: AgencyType[]): void{
    const group: any = {}
    const agencyTypesControls = <FormGroup>this.agencyTypeFormGroup.get('agencyTypeGroup');

    agencyTypes.forEach((agencyType) => {

      if(agencyType.id){
        let agencyTypeIsSet = this.isAgencyTypeSet(this.agencyToEdit, agencyType.id.toString());
        let control = agencyTypesControls.get(agencyType.name);
        if(control){
          control.setValue(agencyTypeIsSet);
        } else {
          agencyTypesControls.addControl(agencyType.name, new FormControl(agencyTypeIsSet));
        }
        // agencyTypesControls.removeControl(agencyType.name);
      }
    })
  }

  public ngOnInit(): void {
   // this.agencyTypeGroup = this.fb.group({
   //    agencyTypes: this.fb.array([this.initFormField()]),
   //  });


    this.agencyTypes$.subscribe((agencyTypes) => {
      this.agencyTypes = agencyTypes;
      // this.cdr.detectChanges();
    })

    this.initAgency();
  }


  private initAgency(){
    this.agencyAdminState.pipe(select(getAgencyToEdit)).subscribe((agency) => {
      this.agencyToEdit = agency;
      Object.keys(this.agencyFormGroup.controls).forEach(control => {
        // @ts-ignore
        this.agencyFormGroup.controls[control].setValue(this.agencyToEdit[control]);
      });
    })
  }

  get agencyTypesControls(): FormArray {
    return this.agencyTypeFormGroup.controls.agencyTypes
  }

  private isAgencyTypeSet(agency: Agency, agencyTypeId: string): boolean{
    if((typeof agency === 'undefined')||(typeof agency.agencyTypes === 'undefined')){
      return false
    }
    const selectedTypes = agency.agencyTypes;

    let isSet = false
    selectedTypes.forEach((iri) => {
      if(iri.includes(agencyTypeId)){
        isSet = true
      }
    })

    return isSet;
  }

  public save() {
    this.agencyToEdit = this.rebuildAgencyTypeList(this.agencyToEdit);

    Object.keys(this.agencyFormGroup.controls).forEach(control => {
      if(control !== 'agencyTypes'){
      // @ts-ignore
        let value = this.agencyFormGroup.controls[control].value;
        if((control === 'zipCode') &&(value)){
          value = parseInt(value)
        }
      // @ts-ignore
        this.agencyToEdit[control] = value
      }
    });

    if(this.editMode === 'add'){
      this.agencyService.add(this.agencyToEdit).pipe(
        first(),
        catchError(error => {
          this.printErrorMessage(error);
          return EMPTY;
        })).subscribe(() => {
        this.notifyService.toast('success', 'Agentur hinzugefügt');
      });
    } else {
      this.agencyService.update(this.agencyToEdit).pipe(
        first(),
        catchError(error => {
          this.printErrorMessage(error);
          return EMPTY;
        })).subscribe(() => {
        this.notifyService.toast('success', 'Änderungen erfolgreich gespeichert.');
      });
    }
  }

  private printErrorMessage(error: any){
    let messageAdd = error.error.error.detail;

    if(error.error.error.detail.includes('Duplicate')) {
      messageAdd = 'E-Mailadresse bereits vorhanden';
    }

    this.notifyService.toast('error', 'Agentur konnte nicht gespeichert werden. ' + messageAdd);
  }

  private rebuildAgencyTypeList(agencyToEdit: Agency): Agency{
    agencyToEdit.agencyTypes = [];

    Object.keys(this.agencyTypeFormGroup.controls.agencyTypeGroup.controls).forEach((key) => {
      const value = this.agencyTypeFormGroup.controls.agencyTypeGroup.get(key)?.getRawValue();
      if(value){
        const result = this.agencyTypes.find((agency) => agency.name === key);
        agencyToEdit.agencyTypes.push('/api/agency_types/'+result?.id)
      }
    })
    this.agencyFormGroup.controls.agencyTypes.setValue(agencyToEdit.agencyTypes);
    return agencyToEdit;
  }
}
