import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idFromIri'
})
export class IdFromIriPipe implements PipeTransform {

  transform(iri: string|undefined, ...args: unknown[]): string {
    const lastUrlSegment = iri?.split('?')[0].split('/').pop()

    if(typeof lastUrlSegment === 'undefined'){
      return ''
    }

    return lastUrlSegment;
  }

}
