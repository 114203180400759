import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output, Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Note } from 'src/app/entities/note.entity';
import { Observable } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { JsonApiTypeMeta } from 'src/app/models/JsonApiTypeResponse';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorI18nNotes } from 'src/app/components/paginator/paginatorI18n-notes';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnChanges, AfterViewInit, OnInit {
  displayedColumns: string[] = ['remove', 'updatedAt', 'changeUser', 'note'];
  public dataSource = new MatTableDataSource<Note>();
  public input: any;

  @Output() noteToDelete = new EventEmitter<Note>();
  @Output() noteToEdit = new EventEmitter<Note>();

  @Output() paging = new EventEmitter<PageEvent>();

  @Output() notePinned = new EventEmitter<Note>();

  @Input() notes$: Observable<Note[]> | undefined;
  @Input() dataMeta = {} as JsonApiTypeMeta;

  @ViewChild('paginatorNotes') paginatorNotes!: MatPaginator;

  @ViewChild('notesTable', { static: true, read: ElementRef }) notesTable!: ElementRef<HTMLDivElement>;
  @ViewChild('paginatorNotes', { static: true, read: ElementRef }) paginator!: ElementRef<HTMLDivElement>;

  constructor(
    private readonly translate: TranslateService,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewInit() {
    const paginatorI18 = new PaginatorI18nNotes(this.translate);
    this.paginatorNotes._intl = paginatorI18.getPaginatorIntl();


  }

  public ngOnInit(): void {
    const width = this.notesTable.nativeElement.getBoundingClientRect().width;
    this.renderer.setStyle(this.paginator.nativeElement, 'width', width + 'px');
  }


  public ngOnChanges(changes: SimpleChanges): void {


    if (typeof this.notes$ !== 'undefined') {
      this.notes$.subscribe((notes) => {
        this.dataSource.data = notes;
      });
    }

    const width = this.notesTable.nativeElement.getBoundingClientRect().width;
    this.renderer.setStyle(this.paginator.nativeElement, 'width', width + 'px');
  }

  public deleteNote(note: Note) {
    this.noteToDelete.emit(note);
  }

  public editNote(note: Note) {
    this.noteToEdit.emit(note);
  }

  public itemsPerPages(pageEvent?: PageEvent){
      this.paging.emit(pageEvent)
  }

  public pinnedNoteChange(note: Note) {
    note.isPinned = !note.isPinned;
    this.notePinned.emit(note);
  }
}
