<div class="grid grid-flow-col auto-cols-max text-xs">
  <div class="mr-2.5 w-72">{{label}}</div>
  <ng-container *ngIf="asIcon === false">
    <div class="col-end-12 max max-w-sm">{{value}}</div>
  </ng-container>
  <ng-container *ngIf="asIcon">
    <div class="">
      <mat-icon>{{text}}</mat-icon>
    </div>
  </ng-container>
</div>
