import { createAction, props } from '@ngrx/store';
import { Agency } from 'src/app/entities/agency.entity';

export const loadAgencyAdmins = createAction(
  '[AgencyAdmin] Load AgencyAdmins'
);

export const setAgencyToEdit = createAction(
  '[AgencyDetail] Set Agency to edit',
  props<{agencyToEdit: Agency}>()
);





