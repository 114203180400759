<mat-toolbar color="primary" class="flex justify-between sticky-toolbar">
  <div class="flex items-center">
    <div>
      <app-refresh-publication-button (refreshPublication)="onRefreshPublication($event)"/>
    </div>
    <div class="ml-2.5 flex align-middle text-base">
      @if(publicationSaving$|async){
          <mat-icon class="h-7 ml-2.5">save</mat-icon>
          <div class="h-7 ml-2.5">wird gespeichert...</div>
      }
  </div>
    <span class="spacer"></span>
  </div>
  <div>
    <app-paginator class="block"
                   [pageSize]="1"
                   [length]="publicationCount"
                    (selectedPublication)="onPublicationSelected($event)"
                   aria-label="Select page">
    </app-paginator>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<div class="container align-middle justify-center mt-4 w-full">
  <form [formGroup]="publicationGroup">
    <div>
      <div>
        <div class="flex justify-between ">
          <div class="">
            <a [href]="'https://publikationen.dguv.de/frontend/detail/index?sArticle='+publicationToEdit.shopwareArticleId" target="_blank">
              <img width="130" height="180" [src]="publicationToEdit.imagePath">
            </a>
          </div>
          <div class="w-3/4">
            <div class="flex justify-between mb-5">
              <div>
                <app-prio-button-row [priority]="publicationToEdit.priority || 0" (selectedPrio)="setPrio($event)"></app-prio-button-row>
              </div>
              <div class="flex justify-between align-middle">
                <div class="flex mx-2.5 mdc-form-field align-middle">
                  <mat-icon *ngIf="publicationToEdit.isActive"
                            color="accent"
                            fontSet="material-icons-outlined"
                            class="text-xl align-middle" >
                    check_circle
                  </mat-icon>
                  <mat-icon *ngIf="publicationToEdit.isActive !== true"
                            fontSet="material-icons-outlined"
                            class="opacity-50 text-xl align-middle" >
                    circle
                  </mat-icon>
                  <div class="">
                    Aktiv
                  </div>
                </div>
                <div class="mx-2.5">
                  <mat-slide-toggle [formControl]="publicationGroup.controls.contentIsUpToDate">Inhalt aktuell</mat-slide-toggle>
                </div>
                <div class="mx-2.5">
                  <mat-slide-toggle  [formControl]="publicationGroup.controls.isLatestCD">Aktuelles CD</mat-slide-toggle>
                </div>
                <div class="ml-2.5">
                  <mat-slide-toggle [formControl]="publicationGroup.controls.picturesInDb">Bilder in Bilddatenbank</mat-slide-toggle>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-3 ml-2">
              <app-text-field label="Best.-Nr:" [text]="(publications$|async)?.orderNrNew ?? ''"></app-text-field>
              <app-text-field label="Best.-Nr. Alt:" [text]="(publications$|async)?.orderNrOld ?? ''"></app-text-field>
              <app-text-field label="Bestand:" [text]="(publications$|async)?.stock ?? ''"></app-text-field>
            </div>
            <div class="grid grid-cols-3 m-2">
              <div class="col-span-3">
                <app-text-field label="Titel:" [text]="publicationToEdit.title ?? ''"></app-text-field>
              </div>
            </div>
            <div class="grid grid-rows-2 grid-flow-col grid-cols-3 ml-2">
              <div class="mr-2.5">
                <app-text-field label="Ausgabe:" [text]="publicationToEdit.edition"></app-text-field>
              </div>
              <div class="mr-2.5 mt--2.5">
                <app-text-field label="Akt. Fassung:" [text]="publicationToEdit.updatedVersion"></app-text-field>
              </div>
              <div class="flex row-span-2 col-span-2">
                <div class="w-full mr-2.5">
                  <mat-form-field class="w-full">
                    <mat-label>Überprüfung erforderlich ab</mat-label>
                    <input matInput [matDatepicker]="picker" [formControl]="publicationGroup.controls.reviewRequiredOn">
                    <mat-hint>DD.MM.YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="flex align-bottom w-full ml-2.5 ">
                  <mat-form-field class="w-full" [matTooltip]="publicationGroup.controls.projectFolderPath?.getRawValue()||''">
                    <mat-label>Pfad Projektordner</mat-label>
                    <input matInput [formControl]="publicationGroup.controls.projectFolderPath">
                  </mat-form-field>
                  <a class="copy-icon"  mat-flat-button color="primary" (click)="copyToClipBoard(publicationGroup.controls.projectFolderPath.getRawValue()?.toString()||'')">
                    <mat-icon class="m-0">content_copy</mat-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-4">
          <div class="mr-2.5">
            <app-select [listItems]="divisions" class="w-full"
                        [label]="'Abteilung'"
                        [ariaLabel]="'division'"
                        [acmFormControl]="publicationGroup.controls.division"
                        [optionValuePrefix]="'/api/' + divisionService?.entityName|iriPluralize"
                        [value]="publicationToEdit.division"
                        (selectedElement)="optionSelected($event)"
            >
            </app-select>
          </div>
          <div class="mx-2.5">
            <app-select [listItems]="departments" class="w-full"
                        [label]="'Referat'"
                        [ariaLabel]="'department'"
                        [acmFormControl]="publicationGroup.controls.department"
                        [optionValuePrefix]="'/api/' + departmentService.entityName|iriPluralize"
                        [value]="publicationToEdit.department"
                        (selectedElement)="optionSelected($event)"
            >
            </app-select>
          </div>

          <div class="flex align-bottom mx-2.5">
            <app-select [listItems]="dguvSupervisors" class="w-full"
                        [label]="'DGUV Betreuer'"
                        [ariaLabel]="'dguvSupervisor'"
                        [acmFormControl]="publicationGroup.controls.dguvSupervisor"
                        [optionValuePrefix]="'/api/' + contactService.entityName|iriPluralize"
                        [value]="publicationToEdit.dguvSupervisor"
                        (selectedElement)="optionSelected($event)"
            >
            </app-select>
            <app-mail-to [contactUrl]="apiHost + publicationToEdit?.dguvSupervisor || ''"
                         [subject]="publicationToEdit.orderNrNew+': '+publicationToEdit.title"></app-mail-to>
          </div>

          <div class="flex align-bottom ml-2.5">
            <app-select [listItems]="supervisorsKom" class="w-full"
                        [label]="'Betreuer KOM'"
                        [ariaLabel]="'supervisorKom'"
                        [acmFormControl]="publicationGroup.controls.supervisorKom"
                        [optionValuePrefix]="'/api/'+contactService.entityName|iriPluralize"
                        [value]="publicationToEdit.supervisorKom"
                        (selectedElement)="optionSelected($event)"
            >
            </app-select>
            <app-mail-to [contactUrl]="apiHost + publicationToEdit?.supervisorKom || ''"
                         [subject]="publicationToEdit.orderNrNew+': '+publicationToEdit.title"></app-mail-to>
          </div>
        </div>
        <div class="grid grid-cols-4 grid-flow-row grid-rows-1">
          <div class="mr-2.5 ">
            <mat-form-field class="w-full">
              <mat-label>Anmerkung</mat-label>
              <textarea matInput
                        cdkTextareaAutosize
                        [formControl]="publicationGroup.controls.note">
              </textarea>
            </mat-form-field>
          </div>
          <div class="col-span-2 row-span-1 mx-2.5">
            <div>
              <button mat-button color="primary" (click)="addNote = !addNote" [disabled]="(addNote||editNote)">
                <mat-icon>add</mat-icon>
                <span>Notiz hinzufügen</span>
              </button>
            </div>
            <div *ngIf="(addNote || editNote)">
              <mat-form-field class="w-full">
                <mat-label>Notiz</mat-label>
                <textarea matInput [(ngModel)]="newNote.note"
                          name="newNote"
                          [cdkTrapFocusAutoCapture]="addNote"
                          [cdkTrapFocus]="addNote"
                          [ngModelOptions]="{standalone: true}"
                >
            </textarea>
              </mat-form-field>
              <button mat-raised-button (click)="cancelNote()">Abbrechen</button>
              <button mat-raised-button color="primary" (click)="saveNote()">Speichern</button>
            </div>
            <div class="scrollable-container">
              <app-notes [notes$]="notes$"
                         (noteToDelete)="confirmDelete($event)"
                         (noteToEdit)="newNote = $event; editNote = true"
                         (paging)="getPublicationNotes(publicationToEdit.id, $event)"
                         (notePinned)="notePinned($event)"
                         [dataMeta]="noteDataMeta"
              >

              </app-notes>
            </div> <!-- End Notes -->

            <div class="my-2.5 ">
              <mat-form-field class="w-full">
                <mat-label>Druckspezifikationen</mat-label>
                <textarea matInput
                          cdkTextareaAutosize
                          [formControl]="publicationGroup.controls.printSpecifications">
              </textarea>
              </mat-form-field>
            </div>

            <div class="w-full"> <!-- ARCHIVE -->
              <div>
                <button mat-button color="primary"
                        (click)="confirmArchivePublication(publicationToEdit)"
                        [disabled]="publicationGroup.dirty"
                >
                  <mat-icon>add</mat-icon>
                  <span>Projekt archivieren</span>
                </button>
              </div>
              <div>
                <app-archive [archives$]="publicationArchives$"
                             [dataMeta]="archiveDataMeta"
                             (paging)="refreshPublicationArchive($event)"
                             (archiveToDelete)="confirmDeleteArchive($event)"
                ></app-archive>
              </div>
            </div> <!-- End ARCHIVE -->


          </div> <!-- End Middle Column -->

          <div class="ml-2.5"> <!-- Right Column -->
            <div>
              <app-select [listItems]="publicationStates" class="w-full"
                          [label]="'Status'"
                          [ariaLabel]="'state'"
                          [acmFormControl]="publicationGroup.controls.state"
                          [optionValuePrefix]="'/api/states'"
                          [value]="publicationToEdit.state"
                          (selectedElement)="stateOptionSelected($event)"
              >
              </app-select>
              <mat-error *ngIf="error">
                <div>Fristablauf in ≤ 4 Tagen, die Statusänderung setzt die Priorität herab. Soll die Priorität herab gesetzt werden?</div>
                <button mat-raised-button color="primary" (click)="confirmPriorityViolation(publicationToEdit.state || '')">Ok</button>
                <button mat-raised-button  (click)="cancelStateChange()">Abbrechen</button>
              </mat-error>
            </div>

            <div class="flex">
              <mat-form-field class="w-full">
                <mat-label>Frist</mat-label>
                <input matInput [matDatepicker]="deadlinePicker" [formControl]="publicationGroup.controls.deadline">
                <mat-hint>DD.MM.YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="deadlinePicker"></mat-datepicker-toggle>
                <mat-datepicker #deadlinePicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="flex align-bottom w-full">
              <app-select [listItems]="layoutAgencies" class="w-full"
                          [label]="'Layout'"
                          [ariaLabel]="'layout'"
                          [acmFormControl]="publicationGroup.controls.layout"
                          [optionValuePrefix]="'/api/'+agencyService.entityName|iriPluralize"
                          [value]="publicationToEdit.layout"
                          (selectedElement)="optionSelected($event)"
              >
              </app-select>
              <app-mail-to [contactUrl]="apiHost + publicationToEdit?.layout || ''"
                           [subject]="publicationToEdit.orderNrNew+': '+publicationToEdit.title"></app-mail-to>
            </div>

            <div class="flex align-bottom w-full">
              <app-select [listItems]="graphic3dAgencies" class="w-full"
                          [label]="'Grafiken 3D'"
                          [ariaLabel]="'graphic3d'"
                          [acmFormControl]="publicationGroup.controls.graphic3d"
                          [optionValuePrefix]="'/api/'+agencyService.entityName|iriPluralize"
                          [value]="publicationToEdit.graphic3d"
                          (selectedElement)="optionSelected($event)"
              >
              </app-select>
              <app-mail-to [contactUrl]="apiHost + publicationToEdit?.graphic3d || ''"
                           [subject]="publicationToEdit.orderNrNew+': '+publicationToEdit.title"></app-mail-to>
            </div>

            <div class="flex align-bottom w-full">
              <app-select [listItems]="illustration2dAgencies" class="w-full"
                          [label]="'Illustrationen 2D'"
                          [ariaLabel]="'illustration2d'"
                          [acmFormControl]="publicationGroup.controls.illustration2d"
                          [optionValuePrefix]="'/api/'+agencyService.entityName|iriPluralize"
                          [value]="publicationToEdit.illustration2d"
                          (selectedElement)="optionSelected($event)"
              >
              </app-select>
              <app-mail-to [contactUrl]="apiHost + publicationToEdit?.illustration2d || ''"
                           [subject]="publicationToEdit.orderNrNew+': '+publicationToEdit.title"></app-mail-to>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Component Sidebar Menu -->

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>
