import { Component, OnDestroy, OnInit } from '@angular/core';
import { PublicationStateService } from 'src/app/entities/services/publication-state.service';
import { StateChangeTaskService } from 'src/app/entities/services/state-change-task.service';
import { MatTableDataSource } from '@angular/material/table';
import { PublicationState } from 'src/app/entities/publication-state.entity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-state-tasks-admin',
  templateUrl: './state-tasks-admin.component.html',
  styleUrls: ['./state-tasks-admin.component.scss']
})
export class StateTasksAdminComponent implements OnInit, OnDestroy {

  public dataSource = new MatTableDataSource<PublicationState>();
  private sub: Subscription | undefined;

  public displayedColumns: string[] = [
    'stateName',
    'tasks',
    'tools',
  ];

  constructor(public publicationStateService: PublicationStateService,
              private stateTasks: StateChangeTaskService
  ) {
  }

  public ngOnInit(): void {
    this.sub = this.publicationStateService.entities$.subscribe((entities) => {
      this.dataSource.data = entities;
    });
  }

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public riseState(publicationState:PublicationState){
      publicationState.displayOrder++
  }
}
