import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, finalize, of, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-excel-import',
  templateUrl: './excel-import.component.html',
  styleUrl: './excel-import.component.scss'
})
export class ExcelImportComponent {

  public uploadSuccess = false;
  protected fileName: any;
  private file: any;
  private uploadSub?: Subscription|null;
  protected uploadProgress?: number|null;
  protected response: any;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private configService: ConfigService,
  ) {
  }



  public onFileSelected($event: Event) {
    if (typeof $event.target !== 'undefined') {
      // @ts-ignore
      this.file = $event.target.files[0];
      if (typeof this.file !== 'undefined') {
        this.fileName = this.file.name;
      }
    }
  }

  public sendFile() {
    if (typeof this.file !== 'undefined') {
      this.fileName = this.file.name;
      const formData = new FormData();
      formData.append('file', this.file);

      const upload$ = this.http.post(`${this.configService.config.apiUrl}/api/excel_import_objects`, formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(
        finalize(() => {
          this.reset();
        }),
      );

      this.uploadSub = upload$.pipe(
        catchError(this.handleError.bind(this))
      ).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadProgress = event.total
              ? Math.round(100 * (event.loaded / event.total))
              : 0;
          }
          if (event.type === HttpEventType.Response) {
            if (event.body) {
              this.uploadSuccess = true;
              this.response = event.body;
              this.snackBar.open('File import gestartet', 'close', { duration: 5000, panelClass: 'successSnack' });
            } else {
              this.handleError('Import failed: No response body');
            }
          }
        },
        error: (error) => {
          this.handleError(error);
        },
        complete: () => {
          console.log('Upload complete');
        }
      });
    }
  }


  private handleError(error: HttpErrorResponse | string): any {
    let errorMessage: string;

    if (typeof error === 'string') {
      errorMessage = error;
    } else {
      if (error.error && error.error['@type'] === 'hydra:Error') {
        // Hydra error handling
        errorMessage = `${error.error['hydra:title']}: ${error.error['hydra:description']}`;
        console.error('Hydra Error:', error.error);
      } else if (error.error instanceof ErrorEvent) {
        errorMessage = `An error occurred: ${error.error.message}`;
      } else {
        errorMessage = `Server returned code ${error.status}, error message is: ${error.message}`;
      }
    }

    this.uploadSuccess = false;
    this.snackBar.open(errorMessage, 'close', {
      panelClass: 'errorSnack',
      duration: 50000
    });
    console.error('Error details:', errorMessage);

    // Let the app keep running by returning an empty result.
    return of(null);
  }

  public cancelUpload() {
    if(this.uploadSub){
      this.uploadSub.unsubscribe();
      this.reset();
      this.snackBar.open('Import cancelled', 'close', { panelClass: 'errorSnack' });
    }
  }

  private reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  public fileSelected() {
    return typeof this.fileName === 'undefined';
  }
}
