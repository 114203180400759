import { Component } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { NotifyService } from 'src/app/services/notify.service';
import { Division } from 'src/app/entities/division.entity';
import { MatTableDataSource } from '@angular/material/table';
import { Department } from 'src/app/entities/department.entity';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteDivisionDialogComponent, DeleteDivisionDialogData
} from 'src/app/modules/administration/dialogs/delete-division-dialog/delete-division-dialog.component';

@Component({
  selector: 'app-abteilung-admin',
  templateUrl: './abteilung-admin.component.html',
  styleUrls: ['./abteilung-admin.component.scss']
})
export class AbteilungAdminComponent {
  private divisionService: EntityCollectionService<Division>;

  public dataSource = new MatTableDataSource<Division>();
  public divisionToEdit = {} as Division;

  public inputIsDisabled = true;

  public displayedColumns: string[] = ['id', 'name'];

  constructor(private entityServices: EntityServices,
              private notifyService: NotifyService,
              public dialog: MatDialog,
  ) {
    this.divisionService = this.entityServices.getEntityCollectionService('Division');
    this.divisionService.getAll();

    this.divisionService.entities$.subscribe((divisions) => {
      this.dataSource.data = divisions
    });
  }

  public addDivision() {
    this.divisionToEdit = {} as Division;
    this.inputIsDisabled = false;
  }

  public save() {
    if(this.divisionToEdit.id){
      this.divisionService.update(this.divisionToEdit);
      this.viewSaveResult('Änderungen gespeichert');
    } else {
      this.divisionService.add(this.divisionToEdit);
      this.viewSaveResult('Neue Abteilung gespeichert');
    }
  }

  private viewSaveResult(message: string){
    this.notifyService.toast('success', message);
    this.divisionToEdit = {} as Department;
    this.inputIsDisabled = true;
  }

  public confirmDelete(division: Division ) {
    const dialogRef = this.dialog.open(DeleteDivisionDialogComponent, {
      data: {division} as DeleteDivisionDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.divisionService.delete(division).subscribe(() => {
          this.notifyService.toast('success', 'Abteilung wurde gelöscht');
        })
      } else {
        this.notifyService.toast('info', 'Löschen abgebrochen');
      }
    });
  }
}
