import { Directive, ElementRef, EventEmitter, Output, OnInit } from '@angular/core';
import { PaginatorService } from 'src/app/components/paginator/paginator.service';

@Directive({
  selector: '[appScrollNearEnd]'
})
export class ScrollNearEndDirective implements OnInit {
  @Output() nearEnd: EventEmitter<void> = new EventEmitter<void>();

  private threshold = 20;
  private scrollContainer: HTMLElement | null = null;

  constructor(private el: ElementRef, private paginatorService: PaginatorService) {}

  ngOnInit(): void {
    this.scrollContainer = this.el.nativeElement.closest('mat-sidenav-content');
    if (!this.scrollContainer) {
      console.warn('ScrollNearEndDirective: Scroll-Container wurde nicht gefunden.');
      return;
    }

    this.scrollContainer.addEventListener('scroll', this.onScroll.bind(this));
  }

  private onScroll = (): void => {
    if (!this.scrollContainer) return;

    this.paginatorService.scrollPosition = this.scrollContainer;

    const scrollTop = this.scrollContainer.scrollTop;
    const scrollHeight = this.scrollContainer.scrollHeight;
    const offsetHeight = this.scrollContainer.offsetHeight;

    const bottomPosition = scrollTop + offsetHeight;
    const isNearBottom = (scrollHeight - bottomPosition) < this.threshold;

    if (isNearBottom) {
      this.nearEnd.emit();
    }
  };

  ngOnDestroy(): void {
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('scroll', this.onScroll);
    }
  }
}
