import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { PublicationState } from 'src/app/entities/publication-state.entity';

@Injectable({
  providedIn: 'root'
})
export class PublicationStateService extends EntityCollectionServiceBase<PublicationState>{

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('PublicationState', serviceElementsFactory);
  }
}

