import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { Agency } from 'src/app/entities/agency.entity';
import {
  BehaviorSubject,
  catchError,
  interval,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  takeWhile,
  tap
} from 'rxjs';
import { Contact } from 'src/app/entities/contact.entity';
import { Division } from 'src/app/entities/division.entity';
import { Publication } from 'src/app/entities/publication.entity';
import { Department } from 'src/app/entities/department.entity';
import { Note } from 'src/app/entities/note.entity';
import { PublicationState } from 'src/app/entities/publication-state.entity';
import { PublicationArchive } from 'src/app/entities/publication-archive.entity';
import { JsonApiTypeMeta, JsonApiTypeResponse } from 'src/app/models/JsonApiTypeResponse';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  PublicationDetailState
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.reducer';
import { NotifyService } from 'src/app/services/notify.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/entities/services/user.service';
import { SearchService } from 'src/app/services/search.service';
import {
  getPublicationDataSavingState,
  getPublicationToEdit
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.selectors';
import {
  setPublicationDirty,
  setPublicationSaving,
  setPublicationToEdit
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.actions';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { StateChange } from 'src/app/entities/state-change.entity';
import { HttpErrorResponse } from '@angular/common/http';
import { MailTo } from 'src/app/models/mail-to.model';
import {
  DeleteNoteDialogComponent,
  DeleteNoteDialogData
} from 'src/app/modules/publication-detail/components/dialogs/delete-note-dialog/delete-note-dialog.component';
import {
  ArchivePublicationDialogComponent,
  ArchivePublicationDialogData
} from 'src/app/modules/publication-detail/components/dialogs/archive-publication/archive-publication-dialog.component';
import {
  DeleteDialogComponent,
  DeleteDialogData
} from 'src/app/modules/publication-detail/components/dialogs/delete-dialog/delete-dialog.component';
import { NoteService } from 'src/app/entities/services/note.service';
import { CacheService } from 'src/app/services/cache.service';
import { ContactService } from 'src/app/entities/services/contact.service';
import { DivisionService } from 'src/app/entities/services/division.service';
import { DepartmentService } from 'src/app/entities/services/department.service';
import { AgencyService } from 'src/app/entities/services/agency.service';
import { PublicationService } from 'src/app/entities/services/publication.service';


export interface DialogData {
  publication: Publication;
  publicationStates: PublicationState[]
  dguvSupervisors: Contact[];
  supervisorsKom: Contact[];
  divisions: Division[]
  departments: Department[]
  layoutAgencies: Agency[]
  graphic3dAgencies: Agency[]
  illustration2dAgencies: Agency[]
}

@Component({
  selector: 'app-publication-detail-dialog',
  templateUrl: './publication-detail-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicationDetailDialogComponent implements OnInit, AfterViewInit, OnDestroy{


  public graphic3dAgencies: Agency[] = [];
  public illustration2dAgencies: Agency[] = [];
  public layoutAgencies: Agency[] = [];

  public dguvSupervisors: Contact[] = [];

  public divisions: Division[] = [];

  public publications$ =  new Observable<Publication>();

  public departments: Department[] = []

  // private noteService: EntityCollectionService<any>;
  public notes$ = new BehaviorSubject<Note[]>([]);

  public publicationStates: PublicationState[] = [];

  public publicationGroup = this.fb.group({
    isActive: [false],
    stock: [0],
    division: [''],
    department: [''],
    deadline: [''],
    dguvSupervisor: [''],
    supervisorKom: [''],
    state: [''],
    layout: [''],
    graphic3d: [''],
    illustration2d: [''],
    note: [''],
    reviewRequiredOn: [''],
    picturesInDb: [false],
    contentIsUpToDate: [false],
    isLatestCD: [false],
    priority: [0],
    scriptInfo: [{value: '', disabled:true}],
    projectFolderPath: [''],
    printSpecifications: [''],
  });

  public addNote = false;
  public newNote: Note = {} as Note;

  public supervisorsKom: Contact[] = [];

  public publicationToEdit: Publication = {} as Publication;

  public publicationArchiveService: EntityCollectionService<PublicationArchive>;
  public publicationArchives$= new BehaviorSubject<PublicationArchive[]>([]);

  public apiHost: string;
  private archiveRemark = '';
  public editNote = false;

  public error = false;

  private notesItemsPerPage = 5
  private notesPage = 1;
  private notes: Note[] = [];
  public noteDataMeta = {} as JsonApiTypeMeta;

  private archiveItemsPerPage = 5
  private archivePage = 1;
  public archiveDataMeta = {} as JsonApiTypeMeta;

  @ViewChild('noteInput') noteInput!: TemplateRef<any>;
  @ViewChild('contextToolsMenu') contextToolsMenu!: TemplateRef<any>;
  private autosaveCycle: ReturnType<typeof setInterval> |undefined;

  private notesSub: Subscription;
  private publicationSub?: Subscription;
  private routerSub?: Subscription;
  private paginatorSub?: Subscription;

  public publicationCount = 0;

  protected publicationSaving$?: Observable<boolean>;

  private autoSaveSubscription?: Subscription;
  private autoSaveIsActive: boolean = true; // Dieser Flag kontrolliert, ob die Auto-Speicherung weiterhin ausgeführt werden soll

  constructor(private entityServices: EntityServices,
              private fb: FormBuilder,
              private publicationDetailState: Store<PublicationDetailState>,
              private cd: ChangeDetectorRef,
              public notifyService: NotifyService,
              public dialog: MatDialog,
              public dataService: DataService,
              private configService: ConfigService,
              private userService: UserService,
              private router: Router,
              private searchService: SearchService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private noteService: NoteService,
              private cacheService:CacheService,
              protected contactService: ContactService,
              protected divisionService: DivisionService,
              protected departmentService: DepartmentService,
              protected agencyService: AgencyService,
              private publicationService: PublicationService,
  ) {
    this.publicationToEdit = data.publication;

    this.dguvSupervisors = data.dguvSupervisors;
    this.supervisorsKom = data.supervisorsKom;
    this.divisions = data.divisions;
    this.departments = data.departments;
    this.layoutAgencies = data.layoutAgencies;
    this.graphic3dAgencies = data.graphic3dAgencies;
    this.illustration2dAgencies = data.illustration2dAgencies;
    this.publicationStates = data.publicationStates

    this.apiHost = this.configService.config.apiUrl;

    this.notesSub = this.notes$.subscribe((notes) => {
      this.notes = notes;
    });

    this.publicationArchiveService =  this.entityServices.getEntityCollectionService('PublicationArchive');

    this.userService.getAll();

    this.publications$ = of(data.publication);
    this.updatePublicationToEdit(data.publication);

    this.publicationArchiveService.clearCache();
    this.refreshPublicationArchive();

    this.startAutoSaveCycle();
    this.publicationGroup.markAsTouched();
  }

  public ngOnInit(): void {

    this.publicationSaving$ = this.publicationDetailState.pipe(
      select(getPublicationDataSavingState)
    );

    this.publicationService.count$.subscribe((count) =>{
      this.publicationCount = count;
    });

    this.noteService.loaded$.subscribe((loaded) => {
      if(loaded){
        this.notes$.next(this.notes)
      }
    });


    this.searchService.searchReset$.pipe(take(2)).subscribe((searchReset) => {
      if(searchReset){
        this.router.navigate(['/list']);
        this.searchService.searchReset$.next(false);
      }
    })

    this.publicationDetailState.pipe(
      select(getPublicationToEdit)
    ).subscribe((publication) => {
      if(publication.id){
        this.updatePublicationToEdit(publication);
      }
    });

    this.cd.detectChanges();
  }


  public ngOnDestroy() {
    if(this.paginatorSub){
      this.paginatorSub.unsubscribe();
    }

    if(this.routerSub){
      this.routerSub.unsubscribe();
    }

    if(this.notesSub){
      this.notesSub.unsubscribe();
    }
    if(this.publicationSub){
      this.publicationSub.unsubscribe();
    }
    this.publicationDetailState.dispatch(setPublicationDirty({isDirty: false}))

    this.autoSaveIsActive = false;
    if (this.autoSaveSubscription) {
      this.autoSaveSubscription.unsubscribe();
    }
  }

  private resetForm(){
    this.publicationDetailState.dispatch(setPublicationDirty({isDirty: false}));
    Object.keys(this.publicationGroup.controls).forEach(control => {
      // @ts-ignore
      this.publicationGroup.controls[control].setValue(this.publicationToEdit[control]);
    });
  }

  private updatePublicationToEdit(publication: Publication){
    this.publicationDetailState.dispatch(setPublicationDirty({isDirty: false}))
    this.publicationToEdit = publication;

    this.publicationGroup.markAsTouched();
    this.publicationGroup.markAsPristine();

    this.resetForm();

    this.getPublicationNotes(this.publicationToEdit.id);

    this.publicationArchiveService.clearCache();
    this.refreshPublicationArchive();

    this.publications$ = of(publication);
  }

  private startAutoSaveCycle(): void {
    // Überprüfen, ob bereits ein Auto-Save-Zyklus läuft
    if (this.autoSaveSubscription) {
      this.autoSaveSubscription.unsubscribe(); // Stellen Sie sicher, dass keine Duplikate laufen
    }

    this.autoSaveSubscription = interval(1000) // 10 Sekunden Intervall
      .pipe(
        takeWhile(() => this.autoSaveIsActive),
        switchMap(() => {
          // Hier prüfen, ob Änderungen vorhanden sind und ob das Formular gültig ist
          if (this.publicationGroup.dirty && this.publicationGroup.valid) {
            return this.save();
          } else {
            return of([]); // Gibt ein leeres Observable zurück, wenn keine Speicherung erforderlich ist
          }
        })
      )
      .subscribe({
        next: response => {
          // this.publicationDetailState.dispatch(setPublicationToEdit({publicationToEdit: response}));
          if(response?.id){
            console.log('Automatische Speicherung erfolgreich:', response);
            this.publicationGroup.markAsPristine();
            this.publicationGroup.markAsUntouched();
            this.publicationDetailState.dispatch(setPublicationSaving({saving: false}))

            this.getPublicationNotes(this.publicationToEdit.id); // Backend write notes in some cases. Here we load them.
            this.dataService.isLoading = false;
          }
        },
        error: error => {
          console.error('Fehler bei der automatischen Speicherung', error);
        },
        complete: () => {
          console.log('Auto-Save-Zyklus beendet');
        }
      });
  }

  private clearAutosaveCycle() {
    clearInterval(this.autosaveCycle);
    this.autosaveCycle = undefined;
  }

  public getPublicationNotes(publicationId: Number, pageEvent?: PageEvent){
    this.dataService.isLoading = true;
    if(pageEvent){
      if (typeof pageEvent !== 'undefined') {
        this.notesItemsPerPage = pageEvent.pageSize;
        this.notesPage = (pageEvent.pageIndex + 1);
      }
    }
    this.noteService.clearCache();

    const url = this.configService.config.apiUrl+this.configService.config.notesUrl+'?publication.id='+publicationId+'&page='+this.notesPage+'&itemsPerPage='+this.notesItemsPerPage
    this.dataService.makeGetCall<JsonApiTypeResponse<Note>>(url, 'application/vnd.api+json').subscribe((data) => {
      this.noteService.addAllToCache(data.data)
      this.noteDataMeta = data.meta;
      this.notes$.next(data.data)
      this.dataService.isLoading = false;
    });
  }

  public refreshPublicationArchive(pageEvent?: PageEvent){
    this.dataService.isLoading = true;
    if(pageEvent){
      if (typeof pageEvent !== 'undefined') {
        this.archiveItemsPerPage = pageEvent.pageSize;
        this.archivePage = (pageEvent.pageIndex + 1);
      }
    }
    const url = this.configService.config.apiUrl+this.configService.config.publicationArchiveUrl+'?publication.id='+this.publicationToEdit.id+'&page='+this.archivePage+'&itemsPerPage='+this.archiveItemsPerPage
    this.dataService.makeGetCall<JsonApiTypeResponse<PublicationArchive>>(url, 'application/vnd.api+json').subscribe((data) => {
      this.publicationArchiveService.addAllToCache(data.data)
      this.archiveDataMeta = data.meta;
      this.publicationArchives$.next(data.data)
      this.dataService.isLoading = false;
    });
  }

  private loadPublication(id: number){
    this.publications$ = this.publicationService.getByKey(id);
    this.publications$.subscribe((publication) => {
      this.updatePublicationToEdit(publication);
    })
  }

  public stateOptionSelected(event: MatSelectChange){

    this.clearAutosaveCycle();
    this.publicationGroup.markAsUntouched();

    const stateIri = event.value.toString();
    const stateId = parseInt(stateIri.split('?')[0].split('/').pop() ?? '');

    this.publicationService.errors$.subscribe((error) => {
      if(error){
        this.error = true;
        this.publicationGroup.controls.state.setErrors({'validation':true})
      }
    })

    this.updatePublicationState(stateId, false);
    this.optionSelected(event);
  }

  public confirmPriorityViolation(stateIri: string) {
    const stateId = parseInt(stateIri.split('?')[0].split('/').pop() ?? '');

    if(stateId) {
      this.updatePublicationState(stateId, true);
      this.error = false;
    }
  }

  public cancelStateChange() {
    this.error = false;
    this.loadPublication(this.publicationToEdit.id);
    this.startAutoSaveCycle();
  }

  private updatePublicationState(stateId: number, prioViolationConfirmed: boolean) {
    this.dataService.isLoading = true;
    const data = {
      id: this.publicationToEdit.id,
      newState: stateId,
      publicationId: this.publicationToEdit.id,
      prioViolationConfirmed: prioViolationConfirmed
    } as StateChange;

    this.dataService.makePutCall<StateChange>(this.apiHost + '/api/state_changes/' + this.publicationToEdit.id, data)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.error = true;
        throw error;
      }))
      .subscribe((response) => {
          this.dataService.isLoading = false;
          // @ts-ignore
          if (response?.result[this.configService.config.stateChangeTasks.renderEmailBody]) {
            // @ts-ignore
            const mailTo: MailTo = JSON.parse(response?.result[this.configService.config.stateChangeTasks.renderEmailBody] || '');
            this.openMailClient(mailTo);
          }
          this.notifyService.toast('success', 'Status Aktualisiert');
          this.loadPublication(this.publicationToEdit.id);

          this.cacheService.clear(this.configService.config.notesUrl)

          this.getPublicationNotes(this.publicationToEdit.id);
          this.publicationGroup.markAsPristine();
          this.publicationGroup.markAsUntouched();
          this.startAutoSaveCycle();
        },
      );
  }

  private openMailClient(mailTo: MailTo){
    window.location.href = `mailto:${mailTo.receiver}?subject=${mailTo.subject}&body=${mailTo.body}`;
  }

  public optionSelected(event: MatSelectChange) {
    const property = event.source.ariaLabel;
    if(event?.value){
      // @ts-ignore
      this.publicationToEdit[property] = event.value.toString()
    } else {
      // @ts-ignore
      this.publicationToEdit[property] = null;
    }
    this.publicationDetailState.dispatch(setPublicationToEdit({publicationToEdit: this.publicationToEdit}));
  }

  public save(): Observable<any> {
    this.dataService.isLoading = true;
    this.publicationDetailState.dispatch(setPublicationSaving({saving: true}))
    Object.keys(this.publicationGroup.controls).forEach(control => {
      // @ts-ignore
      this.publicationToEdit[control] = this.publicationGroup.controls[control].value;
    });

    this.publicationToEdit.notes = undefined; //Notes are handled independent of publications

    return this.publicationService.update(this.publicationToEdit);
  }

  ngAfterViewInit() {
    // Forcing change detection to fix ExpressionChangedAfterItHasBeenCheckedError
    // https://indepth.dev/posts/1001/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error#forcing-change-detection
    this.cd.detectChanges();

    // this.sideNavService.setTools(this.contextToolsMenu);
  }

  public setPrio(prio: 1|2|3|4) {
    if(prio === this.publicationGroup.controls.priority.getRawValue()){
      this.publicationGroup.controls.priority.setValue(null);
    } else {
      this.publicationGroup.controls.priority.setValue(prio);
    }

    this.publicationGroup.controls.priority.markAsDirty();
    this.publicationGroup.controls.priority.markAsTouched();
  }

  public confirmDelete(note: Note ) {
    const dialogRef = this.dialog.open(DeleteNoteDialogComponent, {
      data: {note} as DeleteNoteDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.noteService.removeOneFromCache(note);

        this.noteService.delete(note).subscribe(() => {
          this.notifyService.toast('success', 'Notiz wurde gelöscht');
          this.loadPublication(this.publicationToEdit.id);
        })
      } else {
        this.notifyService.toast('info', 'Löschen abgebrochen');
      }
    });
  }

  public saveNote() {
    if(this.addNote){
      const data = {
        note: this.newNote.note,
        createdAt: new Date().toUTCString(),
        publication: 'api/publications/'+this.publicationToEdit.id
      } as Note;

      this.noteService.addOneToCache(data);

      this.noteService.add(data).subscribe((result) => {
        this.notifyService.toast('success', 'Notiz gespeichert');
        this.getPublicationNotes(this.publicationToEdit.id);
        this.cancelNote();
        this.loadPublication(this.publicationToEdit.id);
      })
    } else {
      delete this.newNote.updatedAt;
      this.noteService.update(this.newNote).subscribe((result) => {
        this.notifyService.toast('success', 'Notiz aktualisiert');
        this.cancelNote();
        this.loadPublication(this.publicationToEdit.id);
      })
    }
  }

  public confirmArchivePublication(publication: Publication) {
    const dialogRef = this.dialog.open(ArchivePublicationDialogComponent, {
      data: {publication: publication, archiveRemark: this.archiveRemark} as ArchivePublicationDialogData,
    });

    dialogRef.afterClosed().subscribe(archiveRemark => {
      if (archiveRemark) {
        const data = {
          "publication": this.configService.config.publicationUrl +'/' + this.publicationToEdit.id,
          "archiveRemark": archiveRemark
        };
        this.dataService.makePostCall(this.apiHost + this.configService.config.publicationArchiveUrl, data).subscribe((result) => {
          this.resetPublicationPropertiesAfterArchive(publication).subscribe((result) => {
            this.notifyService.toast('success', 'Publikation wurde archiviert');
            this.loadPublication(publication.id);
          })
        });
      } else {
        this.notifyService.toast('info', 'Archivieren abgebrochen');
      }
    });
  }

  /**
   * After Archiving a Publication, some Values should be resettet automatically
   * Issue #120
   * @param publication
   */
  public resetPublicationPropertiesAfterArchive(publication: Publication){
    const propertiesToReset = [
      'supervisorKom',
      'state',
      'deadline',
      'layout',
      'graphic3d',
      'illustration2d'
    ];
    const obj = publication as Record<string, any>;

    for (const prop of propertiesToReset) {
      if (obj.hasOwnProperty(prop)) {
        obj[prop] = null;
      }
    }
    obj['notes'] = [];
    this.updatePublicationToEdit(publication);
    return this.publicationService.update(obj);
  }

  public cancelNote() {
    this.addNote = false;
    this.editNote = false;
    this.newNote = {} as Note;
  }

  public copyToClipBoard(text: string) {
    if (!navigator.clipboard) {
      this.notifyService.toast('error', 'Ihr Brower unterstützt den Zugriff auf die Zwischenablage nicht');
    }

    navigator.clipboard.writeText(text).then(r => {
      this.notifyService.toast('info', 'Text in die Zwischenablage kopiert');
    })
  }

  public confirmDeleteArchive(archive: PublicationArchive) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: "Archiv löschen",
        content: "Dieses Archiv wirklich löschen: "+archive.archiveRemark
      } as DeleteDialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.publicationArchiveService.delete(archive).subscribe(() => {
          this.notifyService.toast('success', 'Archiv wurde gelöscht');
          this.loadPublication(this.publicationToEdit.id);
        })
      } else {
        this.notifyService.toast('info', 'Löschen abgebrochen');
      }
    });
  }

  public notePinned(changedNote: Note) {
    this.dataService.isLoading = true;
    const publicationId =  changedNote.publication?.split('?')[0].split('/').pop()
    const url = this.apiHost + '/api/publications/'+publicationId+'/notes/set_is_pinned_false'
    this.dataService.makePutCall<Publication>(url, {}).subscribe((response) => {

      if(changedNote.isPinned){
        this.noteService.update(changedNote).subscribe((result) => {
          this.notes.forEach((data) => {
            if(data.isPinned && (data !== changedNote)){
              data.isPinned = false;
            }
          })
          this.showNotePinnedResult('Notiz fixiert');
        })
      } else {
        this.showNotePinnedResult('Fixierung entfernt');
      }
    })
  }

  private showNotePinnedResult(message: string){
    this.notifyService.toast('success', message);
    this.getPublicationNotes(this.publicationToEdit.id);
    this.dataService.isLoading = false;
  }

  public onRefreshPublication($event: Publication) {
    if($event.id){
      this.publications$ = of($event);
      this.updatePublicationToEdit($event);

      this.notifyService.toast('success', 'Publikation wurden erfolgreich aktualisiert');
    }
  }

  public onPublicationSelected($event: Publication) {
    this.publicationDetailState.dispatch(setPublicationToEdit({publicationToEdit: $event}))
  }
}
