import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/entities/services/user.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { User } from 'src/app/entities/user.entity';

@Pipe({
  name: 'userFromIri'
})
export class UserFromIriPipe implements PipeTransform, OnDestroy {

  private user$ = new ReplaySubject<User|undefined>();
  private sub: Subscription|undefined;

  constructor(private userService: UserService) {
  }
  transform(iri: string): ReplaySubject<User|undefined> {
    const userIdString = iri?.split('?')[0].split('/').pop();

    let userId = 0;

    if(userIdString){
      userId = parseInt(userIdString);
    }

    this.sub = this.userService.loaded$.subscribe((loaded) => {
      if(loaded === false){
        return this.userService.getAll();
      } else {
        return this.userService.entities$.subscribe((users) => {
            let foundUser = users.find(user => user.id === userId);
            this.user$.next(foundUser);
          })
      }
    } )

    return this.user$;
  }

  public ngOnDestroy(): void {
      if (this.sub){
        this.sub.unsubscribe();
      }
  }


}
