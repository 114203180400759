import { Injectable } from '@angular/core';
import { NgToastStackService, NgToastStackType } from 'ng-toast-stack';

type ToastType = 'success' | 'error' | 'info';
const defaultOptions: Partial<NgToastStackType> = {
  autoCloseTimer: 3000
};

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private snackBar: NgToastStackService) {}

  toast(type: ToastType, message: string, overrideOptions?: NgToastStackType) {
    switch (type) {
      case 'info':
        return this.snackBar.push({
          icon: false,
          title: 'Info',
          msg: message,
          ...defaultOptions,
          ...overrideOptions
        });
      case 'success':
        return this.snackBar.success({
          msg: message,
          ...defaultOptions,
          ...overrideOptions
        });
      case 'error':
        return this.snackBar.error({
          autoClose: false,
          msg: message,
          ...defaultOptions,
          ...overrideOptions
        });
    }
  }
}
