import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Publication } from 'src/app/entities/publication.entity';
import { SearchService } from 'src/app/services/search.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PublicationService } from 'src/app/entities/services/publication.service';
import { JsonApiTypeMeta, JsonApiTypeResponse } from 'src/app/models/JsonApiTypeResponse';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent {

  public formCtrl = new FormControl('');

  public filteredResults$ = new BehaviorSubject<Publication[]>([]);

  @Input() results = 10;
  @Output() dataMeta = new EventEmitter<JsonApiTypeMeta>();
  @Output() result = new EventEmitter<Publication[]>();
  @Output() reset = new EventEmitter<boolean>();

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public searchValue: string|null = '';
  public searchValues: string[] = [];

  constructor(private searchService:SearchService,
              private publicationService:PublicationService,
              private configService: ConfigService,
              public dataService: DataService,
  ) {
    this.formCtrl.valueChanges.subscribe((value) => {
      const searchString = '&orderNumber='+value;

      const url = `${this.configService.config.apiUrl + this.configService.config.publicationUrl}?page=1&itemsPerPage=10${searchString}`;
      this.dataService.makeGetCall<JsonApiTypeResponse<Publication>>(url, 'application/vnd.api+json').subscribe((result) => {
        this.searchValue = value;
        this.filteredResults$.next(result.data);
      })
    })
  }

  public loadPublication() {
    if(this.searchValue){
      const search = this.searchService.createPropertySearchObject('orderNumber', this.searchValue ?? '');
      this.searchService.search = [search]
      // searchString = this.searchService.searchString;
    } else {
      this.searchService.search = []
    }

    this.searchService.getSearchResultElement(1, this.results).subscribe((result) => {
      console.log('getSearchResultElement', result);
      this.publicationService.clearCache();
      this.publicationService.addAllToCache(result.data);
      this.dataMeta.emit(result.meta);
      this.result.emit(result.data);
      this.searchService.quickSearchResult$.next(result.data);
    })
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.searchValue = value;
      this.searchValues = [value];
    }

    event.chipInput!.clear();

    this.formCtrl.setValue(null);
    this.loadPublication();
  }

  remove(searchValue: string): void {
    const index = this.searchValues?.indexOf(searchValue);

    this.searchValue = null;
    if (index >= 0) {
      this.searchValues.splice(index, 1);
    }
    this.loadPublication();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.publicationService.clearCache();
    this.searchValues = [event.option.viewValue];
    this.searchValue = event.option.value.orderNrNew.trim();
    this.searchInput.nativeElement.value = '';
    this.formCtrl.setValue(null);

    this.loadPublication();
  }
}
