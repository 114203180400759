<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="stateName">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="tasks">
    <th mat-header-cell *matHeaderCellDef> Tasks </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.id|tasksOfStateByIri|async; let tasks">
        <div *ngFor="let task of tasks">
          <div>{{task.taskName}}</div>
        </div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="tools">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="flex">
        <div>
          <a [routerLink]="['edit', element.id]" >
            <mat-icon class="black-icon">edit</mat-icon>
          </a>
        </div>
        <div>
          <mat-icon class="black-icon">arrow_upward_alt</mat-icon>
        </div>
        <div>
          <mat-icon class="black-icon">arrow_downward_alt</mat-icon>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
