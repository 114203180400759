import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { PublicationArchive } from 'src/app/entities/publication-archive.entity';
import { MatTableDataSource } from '@angular/material/table';
import { JsonApiTypeMeta } from 'src/app/models/JsonApiTypeResponse';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorI18n } from 'src/app/components/paginator/paginatorI18n';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnChanges, AfterViewInit {
  displayedColumns: string[] = [
    'createdAt',
    'orderNrNew',
    'title',
    'note',
    'description',
  ];

  public dataSource = new MatTableDataSource<PublicationArchive>();

  @Output() paging = new EventEmitter<PageEvent>();
  @Output() archiveToDelete = new EventEmitter<PublicationArchive>();

  @Input() archives$: Observable<PublicationArchive[]> | undefined;
  @Input() dataMeta = {} as JsonApiTypeMeta;

  @ViewChild('paginatorArchive') paginatorArchive!: MatPaginator;

  constructor(private readonly translate: TranslateService,
              private paginatorIntl: MatPaginatorIntl) {

  }

  ngAfterViewInit() {
    const paginatorI18 = new PaginatorI18n(this.translate);

    this.paginatorIntl.itemsPerPageLabel = 'Archive je Seite';
    this.translate.get('NEXT_PAGE_LABEL').subscribe((value) => {
      this.paginatorIntl.nextPageLabel = value;
    });
    this.translate.get('PREVIOUS_PAGE_LABEL').subscribe((value) => {
      this.paginatorIntl.previousPageLabel = value;
    });
    this.translate.get('FIRST_PAGE_LABEL').subscribe((value) => {
      this.paginatorIntl.firstPageLabel = value;
    });
    this.translate.get('LAST_PAGE_LABEL').subscribe((value) => {
      this.paginatorIntl.lastPageLabel = value;
    });

    this.paginatorIntl.getRangeLabel = paginatorI18.getRangeLabel.bind(this);

    this.paginatorArchive._intl = this.paginatorIntl;
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.archives$ !== 'undefined') {
      this.archives$.subscribe((archives) => {
        this.dataSource.data = archives;
      });
    }
  }

  public itemsPerPages(pageEvent?: PageEvent){
    this.paging.emit(pageEvent)
  }

  public deleteArchive(archive: PublicationArchive) {
    this.archiveToDelete.emit(archive)
  }
}
