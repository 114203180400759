<div class="mat-elevation-z2">
  <table mat-table [dataSource]="dataSource" >

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef > Datum</th>
      <td mat-cell *matCellDef="let row">
        <div>{{row.createdAt| date: 'dd.MM.yyyy'}}</div>
        <div>{{(row.changeUser|userFromIri|async)?.initials}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="orderNrNew">
      <th mat-header-cell *matHeaderCellDef > Bestell.-Nr.</th>
      <td mat-cell *matCellDef="let row"> {{row.orderNrNew}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef >Bezeichnung</th>
      <td mat-cell *matCellDef="let row"> {{row.title}} </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef >Bemerkung</th>
      <td mat-cell *matCellDef="let row"> {{row.archiveRemark}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef ></th>
      <td mat-cell *matCellDef="let row" class="text-end">
        <a target="_blank" [routerLink]="['archive/', row.id]">
          <mat-icon class="black-icon">description</mat-icon>
        </a>
        <mat-icon class="black-icon" (click)="deleteArchive(row)">delete</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator #paginatorArchive  (page)="itemsPerPages($event)"
                 [length]="dataMeta.totalItems"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 aria-label="Select page of archives">
  </mat-paginator>
</div>

