import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Publication } from 'src/app/entities/publication.entity';

export interface ArchivePublicationDialogData {
  publication: Publication
  archiveRemark: string
}


@Component({
  selector: 'app-archive-publication-dialog',
  templateUrl: './archive-publication-dialog.component.html',
})
export class ArchivePublicationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ArchivePublicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArchivePublicationDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
