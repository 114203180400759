import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements AfterViewInit{
  @Output() selectedElement = new EventEmitter<any>();

  @Input() listItems?: any[]
  @Input() value: any;
  @Input() optionValuePrefix = '/api/';
  @Input() ariaLabel = '';
  @Input() label: string = '';
  @Input() acmFormControl = new FormControl('', Validators.required);
  @Input() required = false;
  @Input() validator = Validators.required;

  constructor(private cd: ChangeDetectorRef) {
    if (this.required){
      this.acmFormControl = new FormControl(this.value, this.validator);
    }
  }

  public selectElement(event: MatSelectChange): void {
    this.selectedElement.emit(event);
  }

  ngAfterViewInit() {
    // Forcing change detection to fix ExpressionChangedAfterItHasBeenCheckedError
    // https://indepth.dev/posts/1001/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error#forcing-change-detection
    this.cd.detectChanges();
  }
}
