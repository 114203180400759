import { Injectable } from '@angular/core';
import { JsonApiTypeMeta } from 'src/app/models/JsonApiTypeResponse';
import { Publication } from 'src/app/entities/publication.entity';

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {

  public currentIndex = 1;
  public meta = {} as JsonApiTypeMeta
  public scrollPosition: HTMLElement | null = null;
  public allLoadedPublications: Publication[] = []

  public selectedRowId = 0;
  constructor() { }
}
