import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import { getUser } from 'src/app/state/app-user-state/app-user.selectors';
import { Observable } from 'rxjs';
import { logout } from 'src/app/state/app-user-state/app-user.actions';
import { MatSidenav } from '@angular/material/sidenav';
import {
  PublicationDetailState
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.reducer';
import {
  getPublicationDataDirtyState, getPublicationDataSavingState
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.selectors';
import { Publication } from 'src/app/entities/publication.entity';
import {
  setPublicationToEdit
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.actions';
import { NavigationEnd, Router } from '@angular/router';
import { PublicationService } from 'src/app/entities/services/publication.service';
import { ListViewService } from 'src/app/modules/list-view/list-view.service';
import { JsonApiTypeMeta } from 'src/app/models/JsonApiTypeResponse';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit{

  @Output() menuToggle = new EventEmitter<string>();

  @Input() sidenav!: MatSidenav;

  public appUser$ = new Observable<AppUserState>;

  public publicationIsDirty$ = new Observable<boolean>();
  public publicationSaving$= new Observable<boolean>();
  public publicationCount = 0;
  public currentPath = '';

  constructor(private appUserState: Store<AppUserState>,
              private publicationDetailState: Store<PublicationDetailState>,
              private publicationService:PublicationService,
              private router: Router,
              public listViewService: ListViewService,
              private notifyService:NotifyService
  ) {
  }

  public ngOnInit(): void {

    this.router.events.subscribe(
      (event:any)=>{
        if(event instanceof NavigationEnd){
          this.currentPath = event.url;
        }
      }
    )


    this.appUser$ = this.appUserState.pipe(select(getUser));

    this.publicationIsDirty$ = this.publicationDetailState.pipe(
      select(getPublicationDataDirtyState)
    );

    this.publicationSaving$ = this.publicationDetailState.pipe(
      select(getPublicationDataSavingState)
    );

    this.publicationService.count$.subscribe((count) =>{
      this.publicationCount = count;
    });
  }

  public logout(){
    this.appUserState.dispatch(logout());
  }

  toggleSideNav() {
    this.sidenav?.toggle();
    this.menuToggle.emit();
  }

  public onPublicationSelected($event: Publication) {
      this.publicationDetailState.dispatch(setPublicationToEdit({publicationToEdit: $event}))
  }

  public setSearchMetaData(event: JsonApiTypeMeta){
    this.listViewService.dataMeta$.next(event)
  }

  public setSearchResultData($event: Publication[]) {
    if($event.length === 0){
      this.notifyService.toast('info', 'Keine Publikation gefunden, die den Suchkriterien entsprechen');
    }
  }
}
