import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ConfigService } from 'src/app/services/config.service';
import { MatCard } from '@angular/material/card';

@Component({
  selector: 'app-export-publications',
  templateUrl: './export-publications.component.html',
  styleUrl: './export-publications.component.scss'
})
export class ExportPublicationsComponent {
  public exportTriggered = false;
  public triggerStart = false;
  public download = false;
  public fileName?: string|null;

  constructor(private route: ActivatedRoute,
              private dataService: DataService,
              private c: ConfigService) { }

  ngOnInit(): void {
    this.fileName = this.route.snapshot.paramMap.get('fileName');
    if (typeof this.fileName === 'string') {
      this.download = true;

      const url = this.c.config.apiUrl + '/export/download/'+this.fileName
      this.dataService.downloadFile(url).subscribe(blob => {
        // Erstellen Sie einen Link zum Herunterladen der Datei
        if(blob){
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          if (typeof this.fileName === "string") {
            link.download = this.fileName;
          }
          link.click();
          window.URL.revokeObjectURL(url);
          this.fileName = null;
        }
      });
    }
  }

  public triggerExport(){
      const url = this.c.config.apiUrl + '/export/publications'
      this.triggerStart = true;
      this.dataService.makePostCall(url, null).subscribe((response) => {
        this.triggerStart = false;
        this.exportTriggered = true;
      })
  }
}
