import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Publication } from 'src/app/entities/publication.entity';
import { DivisionService } from 'src/app/entities/services/division.service';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Division } from 'src/app/entities/division.entity';
import { Department } from 'src/app/entities/department.entity';
import { Contact } from 'src/app/entities/contact.entity';
import { Agency } from 'src/app/entities/agency.entity';
import { DepartmentService } from 'src/app/entities/services/department.service';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { AgencyService } from 'src/app/entities/services/agency.service';
import { MatSelectChange } from '@angular/material/select';
import { PublicationStateService } from 'src/app/entities/services/publication-state.service';
import { PublicationState } from 'src/app/entities/publication-state.entity';
import { PropertySearch } from 'src/app/models/propertySearch.model';
import { FilterBuilderService } from 'src/app/services/filter-builder.service';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { JsonApiTypeMeta, JsonApiTypeResponse } from 'src/app/models/JsonApiTypeResponse';
import { PublicationService } from 'src/app/entities/services/publication.service';
import { SearchService } from 'src/app/services/search.service';
import { first } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-detail-search',
  templateUrl: './detail-search.component.html',
  styleUrls: ['./detail-search.component.scss']
})
export class DetailSearchComponent implements OnInit {
  public formGroup = this.fb.group({
    isActive: ['disabled'],
    contentIsUpToDate: ['disabled'],
    isLatestCD: ['disabled'],
    picturesInDb: ['disabled'],
    stock: [0],
    orderNrNew: [''],
    orderNrOld: [''],
    title: [''],
    edition: [''],
    division: [''],
    department: [''],
    deadline: [''],
    dguvSupervisor: [''],
    supervisorKom: [''],
    state: [''],
    layout: [''],
    graphic3d: [''],
    illustration2d: [''],
    note: [''],
    notesNote: [''],
    notesDate: [''],
    notesChangeUser: [''],
    reviewRequiredOn: [''],
    priority: [0],
    scriptInfo: [{value: '', disabled: true}],
    projectFolderPath: [''],
    printSpecifications: [''],
  });

  public publicationSearchValues: Publication = {} as Publication;

  public contactService: EntityCollectionService<Contact>;

  public divisions: Division[] = [];
  public departments: Department[] =[];
  public dguvSupervisors: Contact[] = [];
  public supervisorsKom: Contact[] = [];
  public agencies: Agency[] = [];
  public publicationStates: PublicationState[] = [];
  public searchString$ = new ReplaySubject<string>(1);
  public searchProperties: PropertySearch[] = [];

  @Output() dataMeta = new EventEmitter<JsonApiTypeMeta>();
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(
    public fb: FormBuilder,
    public entityServices: EntityServices,
    public divisionService: DivisionService,
    public departmentService: DepartmentService,
    public agencyService: AgencyService,
    public publicationStateService: PublicationStateService,
    public publicationService: PublicationService,
    public filterBuilderService: FilterBuilderService,
    private configService: ConfigService,
    public dataService: DataService,
    public searchService:SearchService
  ) {
    this.contactService = this.entityServices.getEntityCollectionService('Contact');

     this.divisionService.entities$.subscribe(data => this.divisions = data);
    this.departmentService.entities$.subscribe(data => this.departments = data)
    this.agencyService.entities$.subscribe(data => this.agencies = data);
    this.publicationStateService.entities$.subscribe(data => this.publicationStates = data)

    this.contactService.getWithQuery('isActive=1&contactTypes.name=dguvBetreuer').subscribe((data => this.dguvSupervisors = data));
    this.contactService.getWithQuery('isActive=1&contactTypes.name=betreuerKom').subscribe(data => this.supervisorsKom = data)
  }

  public ngOnInit(): void {

    this.departmentService.loaded$.subscribe((loaded) => {
      if (loaded === false) {
        this.departmentService.getAll();
      }
    });

    this.divisionService.loaded$.subscribe((loaded) => {
      if (loaded === false) {
        this.divisionService.getAll();
      }
    });

    this.publicationStateService.loaded$.subscribe((loaded) => {
      if (loaded === false) {
        this.publicationStateService.getAll();
      }
    });
    this.agencyService.loaded$.subscribe((loaded) => {
      if (loaded === false) {
        this.agencyService.getAll();
      }
    });
  }


  public setPrio(prio: 1 | 2 | 3 | 4) {
    this.searchProperties = this.searchProperties.filter(function(e) { return !e.property.includes('priority') });

    if(prio !== this.formGroup.controls.priority.value){
      this.formGroup.controls.priority.setValue(prio);
      const propertySearch = this.searchService.createPropertySearchObject('priority', prio.toString());
      this.searchProperties.push(propertySearch);
    } else {
      this.formGroup.controls.priority.setValue(0);
    }
  }

  public optionSelected(event: MatSelectChange, collectionService: EntityCollectionService<any>) {
    const property = event.source.ariaLabel;
    const id = event.value?.split("/").pop();

    //Removing eventually existing search value
    this.searchProperties = this.searchProperties.filter(function(e) { return !e.property.includes(property) });

    collectionService.getByKey(id).pipe(first()).subscribe((value) => {
      const propertySearch = {
        property: property+'.name',
        search: value.name
      } as PropertySearch;
      // @ts-ignore
      this.searchProperties.push(propertySearch);

    });
  }

  public contactSelected(event: MatSelectChange, collectionService: EntityCollectionService<any>) {
    const property = event.source.ariaLabel;
    this.searchProperties = this.searchProperties.filter(function(e) { return !e.property.includes(property) });
    const id = event.value?.split('?')[0].split('/').pop();
    collectionService.getByKey(id).pipe(first()).subscribe((contact) => {
      this.searchProperties.push(this.buildContactPropertySearch(contact, 'firstName', property));
      this.searchProperties.push(this.buildContactPropertySearch(contact, 'lastName', property));
    })
  }

  private buildContactPropertySearch(contact: Contact, namePart: string, propertyName: string): PropertySearch {
    return {
      property: propertyName + '.' + namePart,
      // @ts-ignore
      search: contact[namePart]
    } as PropertySearch;

  }

  public dateSelected(event: MatDatepickerInputEvent<any>, property: string) {
    this.searchProperties = this.searchProperties.filter(function(e) { return !e.property.includes(property) });

    const propertySearch = {
      property: property+'[after]',
      search: event.value.toUTCString()
    } as PropertySearch;

    this.searchProperties.push(propertySearch);
  }

  public search() {
    const searchString = this.filterBuilderService.buildSearchUrlExtension(this.searchProperties);
      this.searchService.search = this.searchProperties;
      const url = `${this.configService.config.apiUrl + this.configService.config.publicationUrl}?page=1&itemsPerPage=10000${searchString}`;
      this.dataService.makeGetCall<JsonApiTypeResponse<Publication>>(url, 'application/vnd.api+json').pipe(first()).subscribe((data) => {
        this.publicationService.clearCache();
        this.publicationService.addAllToCache(data.data);

        this.dataMeta.emit(data.meta);
      });
  }

  public textPropertySelected(event: any, property: string) {
    this.searchProperties = this.searchProperties.filter(function(e) { return !e.property.includes(property) });

    const propertySearch = this.searchService.createPropertySearchObject(property, event.currentTarget.value);
    this.searchProperties.push(propertySearch);
  }

  public buttonGroupSelected(event: MatButtonToggleChange, property: string) {
    this.searchProperties = this.searchProperties.filter(function(e) { return !e.property.includes(property) });

    if(event.value === 'true' || event.value === 'false'){
      const propertySearch = this.searchService.createPropertySearchObject(property, event.value);
      this.searchProperties.push(propertySearch);
    }
  }

  public noteSearch(event: any, property: string){
    this.searchProperties = this.searchProperties.filter(function(e) { return !e.property.includes(property) });

    const propertySearch = this.searchService.createPropertySearchObject(property, event.currentTarget.value);
    this.searchProperties.push(propertySearch);
  }

  public resetSearch() {
    this.searchProperties = [];
    this.formGroup.reset();
  }

  public close(){
    this.closeDialog.emit(true);
  }
}
