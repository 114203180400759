import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class UrlInterceptorService implements HttpInterceptor{
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     *  removing trailing slashes from URL.
     *
     *  Can be removed when NGRX/data can be configured not adding a slash to API URL
     */
    const clonedReq = req.clone({
      url: req.url.replace(/\/+$/, '')
    });

    return next.handle(clonedReq)
  }

}
