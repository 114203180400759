import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Note } from 'src/app/entities/note.entity';

export interface DeleteNoteDialogData {
  note: Note
}

@Component({
  selector: 'app-delete-note-dialog',
  templateUrl: './delete-note-dialog.component.html',
})
export class DeleteNoteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteNoteDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
