
<div class="container align-middle justify-center mt-4">
  <form [formGroup]="contactFormGroup" class="mx-auto max-w-max">
    <div>
      <h1 *ngIf="editMode === 'add'" class="text-dguv-blue uppercase">Neuen Betreuer hinzufügen</h1>
      <h1 *ngIf="editMode === 'edit'" class="text-dguv-blue uppercase">Betreuer bearbeiten</h1>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Titel</mat-label>
            <input matInput [formControl]="contactFormGroup.controls.name">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <mat-form-field >
            <mat-label>Anrede</mat-label>
            <!--            <input matInput [formControl]="contactFormGroup.controls.salutation">-->
            <mat-select [formControl]="contactFormGroup.controls.salutation" [aria-label]="'Anrede'">
              <mat-option>--</mat-option>
              <mat-option [value]="'Frau'">Frau</mat-option>
              <mat-option [value]="'Herr'">Herr</mat-option>
              <mat-option [value]="'Divers'">Divers</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Vorname</mat-label>
            <input matInput [formControl]="contactFormGroup.controls.firstName">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <mat-form-field >
            <mat-label>Nachname</mat-label>
            <input matInput [formControl]="contactFormGroup.controls.lastName">
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Telefon</mat-label>
            <input matInput [formControl]="contactFormGroup.controls.phoneNumber">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <mat-form-field >
            <mat-label>E-Mail</mat-label>
            <input matInput [formControl]="contactFormGroup.controls.email">
          </mat-form-field>
        </div>
      </div>
      <div class="flex">
        <div class="mr-2.5">
          <mat-form-field >
            <mat-label>Ort</mat-label>
            <input matInput [formControl]="contactFormGroup.controls.city">
          </mat-form-field>
        </div>
        <div class="ml-2.5">
          <section class="example-section" >
            <ng-container>
              <mat-checkbox [formControl]="contactFormGroup.controls.contactTypes.controls.dguvBetreuer" >DGUV</mat-checkbox>
              <mat-checkbox [formControl]="contactFormGroup.controls.contactTypes.controls.betreuerKom" >KOM</mat-checkbox>
            </ng-container>
          </section>
        </div>
      </div>
      <div class="flex">
        <div>
          <button mat-raised-button color="primary" (click)="save()">Speichern</button>
        </div>
      </div>
    </div>
  </form>
</div>
