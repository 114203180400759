import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { NotesComponent } from './components/notes/notes.component';
import { MaterialSharedModule } from 'src/app/modules/material.shared.module';
import { SpecificationComponent } from './components/specification/specification.component';
import { MailToComponent } from './components/mail-to/mail-to.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { FormsModule } from '@angular/forms';
import { DeleteNoteDialogComponent } from './components/dialogs/delete-note-dialog/delete-note-dialog.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ArchiveComponent } from './components/archive/archive.component';
import { ArchivePublicationDialogComponent } from 'src/app/modules/publication-detail/components/dialogs/archive-publication/archive-publication-dialog.component';
import { ArchiveViewComponent } from './components/archive-view/archive-view.component';
import { RouterLink } from '@angular/router';
import { ArchiveViewRowComponent } from './components/archive-view-row/archive-view-row.component';
import { UserFromIriPipe } from 'src/app/pipes/user-from-iri.pipe';
import { ArchiveViewNoteComponent } from './components/archive-view-note/archive-view-note.component';
import { NgxPrintModule } from 'ngx-print';
import { DeleteDialogComponent } from './components/dialogs/delete-dialog/delete-dialog.component';

@NgModule({
    declarations: [
        NotesComponent,
        SpecificationComponent,
        MailToComponent,
        TextFieldComponent,
        DeleteNoteDialogComponent,
        ArchiveComponent,
        ArchivePublicationDialogComponent,
        ArchiveViewComponent,
        ArchiveViewRowComponent,
        UserFromIriPipe,
        ArchiveViewNoteComponent,
        DeleteDialogComponent
    ],
  exports: [
    MailToComponent,
    TextFieldComponent,
    NotesComponent,
    ArchiveComponent
  ],
    imports: [
        MaterialSharedModule,
        FormsModule,
        A11yModule,
        RouterLink,
        NgxPrintModule,
        NgOptimizedImage
    ]
})
export class PublicationDetailModule { }
