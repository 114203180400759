import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Publication } from 'src/app/entities/publication.entity';

@Injectable({
  providedIn: 'root'
})
export class PublicationService extends EntityCollectionServiceBase<Publication>{

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Publication', serviceElementsFactory);
  }
}
