import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ReplaySubject, Subscription, tap } from 'rxjs';
import { AgencyService } from 'src/app/entities/services/agency.service';
import { Agency } from 'src/app/entities/agency.entity';

@Pipe({
  name: 'agencyFromIri'
})
export class AgencyFromIriPipe implements PipeTransform, OnDestroy {

  private agency$ = new ReplaySubject<Agency|undefined>();
  private sub: Subscription|undefined;

  constructor(private agencyService: AgencyService) {
  }
  transform(iri: string): ReplaySubject<Agency|undefined> {
    const agencyIdString = iri?.split('?')[0].split('/').pop();

    let agencyId = 0;

    if(agencyIdString){
      agencyId = parseInt(agencyIdString);
    }

    this.sub = this.agencyService.loaded$.subscribe((loaded) => {
      if(loaded === false){
        this.agencyService.loading$.pipe(
          tap((isLoading) => {
            if(isLoading === false){
              this.agencyService.getAll();
            }
          })
        )
        return;
      } else {
        return this.agencyService.entities$.subscribe((agencies) => {
          let foundAgency = agencies.find(agency => agency.id === agencyId);
          this.agency$.next(foundAgency);
        })
      }
    } )

    return this.agency$;
  }

  public ngOnDestroy(): void {
    if (this.sub){
      this.sub.unsubscribe();
    }
  }
}
