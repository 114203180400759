import { Injectable } from '@angular/core';
import { PropertySearch } from 'src/app/models/propertySearch.model';
import { FilterBuilderService } from 'src/app/services/filter-builder.service';
import { JsonApiTypeResponse } from 'src/app/models/JsonApiTypeResponse';
import { Publication } from 'src/app/entities/publication.entity';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private _search: PropertySearch[] = [];

  private _searchString = '';

  public searchReset$ = new BehaviorSubject<boolean>(false);

  public quickSearchResult$ = new BehaviorSubject<Publication[]>([])

  private _orderKey = 'orderNrNew';
  private _orderDirection = 'asc';

  constructor(
    private filterBuilderService: FilterBuilderService,
    private configService: ConfigService,
    public dataService: DataService,) {
  }

  public get search(): PropertySearch[] {

    return this._search;
  }

  public set search(search: PropertySearch[]) {
    this._search = search;
  }


  public get searchString(): string {
    this._searchString = '&order[' + this._orderKey + ']=' + this._orderDirection +
      this.filterBuilderService.buildSearchUrlExtension(this._search);

    return this._searchString;
  }

  public getSearchResultElement(page: number, itemsPerPage = 1): Observable<JsonApiTypeResponse<Publication>> {
    const url = `${this.configService.config.apiUrl + this.configService.config.publicationUrl}?page=${page}&itemsPerPage=${itemsPerPage}${this.searchString}`;
    return this.dataService.makeGetCall<JsonApiTypeResponse<Publication>>(url, 'application/vnd.api+json');
  }

  public createPropertySearchObject(property: string, search: string): PropertySearch{
    return  {
      property: property,
      search: search
    } as PropertySearch;
  }


  public get orderKey(): string {
    return this._orderKey;
  }

  public set orderKey(value: string) {
    this._orderKey = value;
  }

  public get orderDirection(): string {
    return this._orderDirection;
  }

  public set orderDirection(value: string) {
    this._orderDirection = value;
  }
}
