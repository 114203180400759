import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Division } from 'src/app/entities/division.entity';

export interface DeleteDivisionDialogData {
  division: Division
}

@Component({
  selector: 'app-delete-division-dialog',
  templateUrl: './delete-division-dialog.component.html',
})
export class DeleteDivisionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteDivisionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDivisionDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
