import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import { getAppUserRoles } from 'src/app/state/app-user-state/app-user.selectors';

@Directive({
  selector: '[ngxRestrictedTo]'
})
export class RestrictedToDirective implements OnInit {
  @Input() ngxRestrictedTo: string | undefined;

  private isVisible = false;

  constructor(
    private appUserState: Store<AppUserState>,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) { }



  public ngOnInit(): void {
    this.appUserState.pipe(
      select(getAppUserRoles)
    ).subscribe( (roles) => {

      if(!roles){
        this.viewContainerRef.clear()
      }

      if(typeof this.ngxRestrictedTo === 'undefined'){
        this.isVisible = false;
        return;
      }

      // If the user has the role needed to
      // render this component we can add it
      if (roles.includes(this.ngxRestrictedTo)) {
        // If it is already visible (which can happen if
        // his roles changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the
          // templateRef to the view using the
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the role,
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    })
  }

}
