import { Injectable } from '@angular/core';
import {
  DefaultHttpUrlGenerator,
  HttpResourceUrls,
  normalizeRoot,
  Pluralizer,
} from '@ngrx/data';

@Injectable()
export class PluralHttpUrlGenerator extends DefaultHttpUrlGenerator {
  constructor(private jobManagerPluralizer: Pluralizer) {
    super(jobManagerPluralizer);
  }

  protected override getResourceUrls(
    entityName: string,
    root: string,
  ): HttpResourceUrls {
    let resourceUrls = this.knownHttpResourceUrls[entityName];
    if (!resourceUrls) {
      const nRoot = normalizeRoot(root);
      const pluralName = this.jobManagerPluralizer.pluralize(entityName);
      const snakeCasePluralName = PluralHttpUrlGenerator.camelCaseToSnakeCase(pluralName);
      const url = `${nRoot}/${snakeCasePluralName}/`;
      resourceUrls = {
        entityResourceUrl: url,
        collectionResourceUrl: url,
      };
      this.registerHttpResourceUrls({ [entityName]: resourceUrls });
    }
    return resourceUrls;
  }

  static camelCaseToSnakeCase(camelCase: string): string {
    let snakeCase = camelCase.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
    snakeCase = snakeCase.replace(/^_/g, '');

    return snakeCase;
  }
}
