<mat-form-field appearance="fill" class="w-full text-xs">
  <mat-label>{{label}}</mat-label>
  <mat-select [formControl]="acmFormControl" [aria-label]="ariaLabel"
              [value]="value"
              (selectionChange)="selectElement($event)"
              [required]="required"
              >
    <mat-option>--</mat-option>
    @for (option of listItems; track option){
      <mat-option  [value]="optionValuePrefix + '/' + option.id">
        {{ option.name ? option.name : ( option.lastName + ', ' + option.firstName ) }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>

