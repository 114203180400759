<div class="flex justify-between">
  <!-- Left -->
  <div class="w-3/5">
    <div>
      <mat-form-field class="example-full-width">
        <mat-label>Format</mat-label>
        <input matInput placeholder="Ex. CD-ROM" >
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>Umfang</mat-label>
        <input matInput >
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Umschlag</mat-label>
        <mat-select >
          <mat-option>--</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Innenteil</mat-label>
        <mat-select >
          <mat-option>--</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Verarbeitung</mat-label>
        <mat-select >
          <mat-option>--</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-full">
        <mat-label>Extras</mat-label>
        <textarea matInput></textarea>
      </mat-form-field>
    </div>

  </div>
  <!-- Right -->
  <div class="w-1/3">
    <mat-card class="w-auto ml-2">
      <section [formGroup]="specification">
        <h4>Spezifikation</h4>
        <p><mat-checkbox formControlName="sticker">Aufkleber</mat-checkbox></p>
        <p><mat-checkbox formControlName="cdDvd">CD/DVD</mat-checkbox></p>
        <p><mat-checkbox formControlName="purAdhesiveBinding">Pur Klebebindung</mat-checkbox></p>
        <p><mat-checkbox formControlName="ringEyeStitching">Ringösenheftung</mat-checkbox></p>
      </section>
    </mat-card>
  </div>
</div>
