import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppUserState} from './app-user.reducer';
import { AppUser } from 'src/app/models/app-user.model';


export const getAppUserState = createFeatureSelector<AppUserState>(
  'appUserState'
);

export const getUserId = createSelector(
  getAppUserState,
  state => state.userId
);

export const getUserLoggedInState = createSelector(
  getAppUserState,
  state => state.username !== ''
);

export const getUser = createSelector(
  getAppUserState,
  state => state
);

export const getToken = createSelector(
  getAppUserState,
  state => state.token
);

export const getAppUserRoles = createSelector(
  getAppUserState,
  state => state.roles
);

export const getUserCookieData = createSelector(
  getAppUserState,
  state => ({
    userId: state.userId,
    username: state.username,
    refreshToken: state.refreshToken,
    token: state.token,
  } as AppUser)
)
