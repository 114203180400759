<div class="flex-container">
  <div class="pt-4 pl-4">
    <h2>Excel Import</h2>
  </div>
  <div class="m-6">
    Upload und Import eines aktualisierten Excel-Exports
  </div>

  <mat-card appearance="outlined" class="m-6">
    <mat-card-content>
      <input type="file" class="file-input"
             (change)="onFileSelected($event)" #fileUpload>

      <div class="file-upload">

        {{fileName || "Bitte XLSX-Datei für den Import auswählen"}}

        <button mat-mini-fab color="primary" class="upload-btn"
                (click)="fileUpload.click()">
          <mat-icon>attach_file</mat-icon>
        </button>

      </div>
    </mat-card-content>

    <mat-card-footer>
      <button [disabled]="fileSelected()" mat-raised-button color="primary" (click)="sendFile()">Import</button>
      <div class="progress">

        <mat-progress-bar class="progress-bar" mode="determinate"
                          [value]="uploadProgress" *ngIf="uploadProgress">
        </mat-progress-bar>

        <mat-icon class="cancel-upload" (click)="cancelUpload()"
                  *ngIf="uploadProgress">delete_forever
        </mat-icon>

      </div>
    </mat-card-footer>
  </mat-card>

  @if(uploadSuccess){
    <mat-card appearance="outlined" class="m-6">
      <div><h3>Import gestartet</h3> <br>Sie werden per E-Mail benachrichtigt, wenn der Import beendet ist.</div>
    </mat-card>
  }

</div>

