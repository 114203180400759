import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { StateChangeTask } from 'src/app/entities/state-change-task.entity';
import { first, map } from 'rxjs/operators';
import { asapScheduler, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateChangeTaskService extends EntityCollectionServiceBase<StateChangeTask>{

  private keys: any;

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('StateChangeTask', serviceElementsFactory);

    this.keys$.subscribe((keys) => {
      this.keys = keys;
    });
  }

  public getTasksByStateId(entityId: number): Observable<StateChangeTask[]|undefined>{
    if (!this.keys.includes(entityId)) {
      this.loadTasksFromApi(entityId);
    }

    return this.entities$.pipe(
      map((entities) => {
        return entities.filter((entity) => entity.state === '/api/states/'+String(entityId));
      })
    );
  }

  private loadTasksFromApi(entityId: number) {
    //asapSchedule because of error "NG0600: Writing to signals is not allowed":
    // https://github.com/ngrx/platform/issues/3932
    asapScheduler.schedule(() => this.getWithQuery('state.id='+entityId).pipe(
      tap((entity) => {
        this.addAllToCache(entity);
      })
    ));
  }
}
