import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListViewComponent } from './components/list-view/list-view.component';
import { PrioIndicatorComponent } from 'src/app/components/prio-indicator/prio-indicator.component';
import { MaterialSharedModule } from 'src/app/modules/material.shared.module';
import { RouterLink } from '@angular/router';
import { ContactService } from 'src/app/entities/services/contact.service';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { defaultDataServiceConfig } from 'src/app/state/config/data-service-config';
import { StateFromIriPipe } from 'src/app/pipes/state-from-iri.pipe';
import { LetDirective } from '@ngrx/component';
import { MatToolbarModule } from '@angular/material/toolbar';



@NgModule({
  providers:[
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
    ContactService
  ],
  declarations: [
    ListViewComponent,
    PrioIndicatorComponent,
    StateFromIriPipe
  ],
    imports: [
        CommonModule,
        MaterialSharedModule,
        RouterLink,
        LetDirective,
        MatToolbarModule,
    ]
})
export class ListViewModule {
  constructor() {
  }
}
