import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ContactService } from 'src/app/entities/services/contact.service';
import { Observable, ReplaySubject, Subscription, tap } from 'rxjs';
import { Contact } from 'src/app/entities/contact.entity';
import { PublicationState } from 'src/app/entities/publication-state.entity';

@Pipe({
  name: 'contactFromIri'
})
export class ContactFromIriPipe implements PipeTransform, OnDestroy {

  private state$ = new ReplaySubject<Contact|undefined>();
  private sub: Subscription|undefined;

  constructor(public contactService: ContactService,) {
  }

  transform(iri: string): ReplaySubject<Contact|undefined> {
    return this.getByIri(iri);
  }

  getByIri(iri: string ): ReplaySubject<Contact|undefined> {
    const stateIdString = iri?.split('?')[0].split('/').pop();
    let contactId = 0;

    if(stateIdString){
      contactId = parseInt(stateIdString);
    }

    this.sub = this.contactService.loaded$.subscribe((loaded) => {
      if(loaded === false){
        this.contactService.loading$.pipe(
          tap((isLoading) => {
            if(isLoading === false){
              this.contactService.getAll();
            }
          })
        )
        return;
      } else {
        return this.contactService.entities$.subscribe((contacts) => {
          let foundContact = contacts.find(contact => contact.id === contactId);
          this.state$.next(foundContact);
        })
      }
    } )

    return this.state$;
  }

  public ngOnDestroy(): void {
    if (this.sub){
      this.sub.unsubscribe();
    }
  }
}
