<ng-container *ngIf="icon">
  <mat-form-field [class]="cssClass">
    <mat-label>{{label}}</mat-label>
    <input  matInput placeholder=" " value=" " disabled>
    <mat-icon matSuffix>{{text}}</mat-icon>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="icon === false">
  <div [class]="'flex '+ cssClass">
    <div class="mr-2.5">{{label}}</div>
    <div class="font-semibold">{{text}}</div>
  </div>
</ng-container>

