import { Pipe, PipeTransform } from '@angular/core';
import { ContactTypeService } from 'src/app/entities/services/contact-type.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContactType } from 'src/app/entities/contact-type.entity';

@Pipe({
  name: 'contactTypeFromIri'
})
export class ContactTypeFromIriPipe implements PipeTransform {

  constructor(public contactTypeService:ContactTypeService) {
  }
  transform(iri: string): Observable<ContactType|undefined> {
    return this.contactTypeService.entities$.pipe(
      map(contactTypes => contactTypes.find(contactType => iri.includes(contactType.id.toString()))),
      first()
    )
  }

}
