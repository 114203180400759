import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-specification',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.scss']
})
export class SpecificationComponent {
  public specification = this.fb.group({
    sticker: false,
    cdDvd: false,
    purAdhesiveBinding: false,
    ringEyeStitching: false,
  });

  constructor(private fb: FormBuilder,) {
  }
}
