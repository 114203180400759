import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { PublicationStateService } from 'src/app/entities/services/publication-state.service';
import { ReplaySubject, Subscription, tap } from 'rxjs';
import { PublicationState } from 'src/app/entities/publication-state.entity';

@Pipe({
  name: 'stateFromIri'
})
export class StateFromIriPipe implements PipeTransform, OnDestroy {

  private state$ = new ReplaySubject<PublicationState|undefined>();
  private sub: Subscription|undefined;
  private isLoadingSub: Subscription|undefined;

  constructor(private stateService: PublicationStateService) {
  }
  transform(iri: string): ReplaySubject<PublicationState|undefined> {
    const stateIdString = iri?.split('?')[0].split('/').pop();

    let stateId = 0;

    if(stateIdString){
      stateId = parseInt(stateIdString);
    }

    // @ts-ignore
    this.sub = this.stateService.loaded$.subscribe((loaded) => {
      if(loaded === false){
        this.stateService.loading$.pipe(
          tap((isLoading) => {
            if(isLoading === false){
              this.stateService.getAll();
            }
          })
        )
        return;
      } else {
        return this.stateService.entities$.subscribe((state) => {
          let foundState = state.find(state => state.id === stateId);
          this.state$.next(foundState);
        })
      }
    } )

    return this.state$;
  }

  public ngOnDestroy(): void {
    if (this.sub){
      this.sub.unsubscribe();
    }
  }
}
