import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Publication } from 'src/app/entities/publication.entity';
import { PaginatorService } from 'src/app/components/paginator/paginator.service';
import { Subscription } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';
import { ListViewService } from 'src/app/modules/list-view/list-view.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy{
  @Input() pageSize = 0;
  @Input() length = 1;

  @Output() selectedPublication = new EventEmitter<Publication>();

  private routerSub?: Subscription;

  constructor(
              public paginatorService: PaginatorService,
              private searchService: SearchService,
              private listViewService: ListViewService,
  ) {
  }

  public ngOnInit(): void {
    this.listViewService.dataMeta$.subscribe((dataMeta) => {
      this.length = dataMeta.totalItems
    })
  }

  public ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

  public nextPage(){
    if(this.paginatorService.currentIndex < this.length){
        this.paginatorService.currentIndex++;
        this.findElementAndEmit()
    }
  }

  public prevPage(){
    if (this.paginatorService.currentIndex > 1){
      this.paginatorService.currentIndex--;
      this.findElementAndEmit()
    }
  }

  private emitEvent(publication: Publication){
    this.selectedPublication?.emit(publication)
  }

  private findElementAndEmit(){
    this.searchService.getSearchResultElement(this.paginatorService.currentIndex)
    .subscribe(element => {
      if(element.data[0]){
        this.paginatorService.selectedRowId = element.data[0].id
        this.emitEvent(element.data[0])
      }
    });
  }
}
