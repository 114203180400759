import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, [Date, HttpResponse<any>]>();

  //TTL: milliseconds = 5 Minutes
  set(key: string, value: HttpResponse<any>, ttl: number = 300000): void {
    const expirationTime = new Date().getTime() + ttl;
    this.cache.set(key, [new Date(expirationTime), value]);
  }

  get(key: string): HttpResponse<any> | null {
    const cached = this.cache.get(key);
    if (!cached) return null;

    const [expirationTime, httpResponse] = cached;
    if (new Date().getTime() > expirationTime.getTime()) {
      this.cache.delete(key);
      return null;
    }

    return httpResponse;
  }

  clear(key?: string): void {
    if (key) {
      for (let cacheKey of this.cache.keys()) {
        if (cacheKey.includes(key)) {
          this.cache.delete(cacheKey);
        }
      }
    } else {
      this.cache.clear();
    }
  }
}
