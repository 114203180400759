import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, EMPTY, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import { getToken } from 'src/app/state/app-user-state/app-user.selectors';
import { logout } from 'src/app/state/app-user-state/app-user.actions';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  private authToken = '';

  constructor(private appUserState: Store<AppUserState>, private router: Router) {
    appUserState.pipe(select(getToken)).subscribe((authToken: string) => {
      if ((typeof authToken !== 'undefined') && (authToken.length > 2)) {
        this.authToken = authToken;
      }
    });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!request.url.includes('api/login_check')){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authToken}`,
        },
      });

    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !request.url.includes('api/login_check') && error.status === 401) {
          this.appUserState.dispatch(logout());
          this.router.navigate(['/']);
          return EMPTY;
        }

        throw error;
      })
    );
  }
}
