import { Injectable } from '@angular/core';
import { JsonApiTypeMeta } from 'src/app/models/JsonApiTypeResponse';
import { Publication } from 'src/app/entities/publication.entity';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ListViewService {

  public page = 1;
  public itemsPerPage = 100;
  public dataMeta$ = new BehaviorSubject<JsonApiTypeMeta>({} as JsonApiTypeMeta);
  public pagerPageIndex = 0;
  public selectedPublication = {} as Publication;

  constructor(private readonly cookieService: CookieService) {
  }

  public resetPagination() {
    this.page = 1;
    this.itemsPerPage = 100;
    this.pagerPageIndex = 0;
  }

  public setPageSizeCookie(pageSize: number) {
    this.cookieService.set('listViewPageSize', pageSize.toString(), 9999, '/');
  }

  public getPageSizeCookie(): number {
    const pageSize = this.cookieService.get('listViewPageSize');

    if (pageSize === '') {
      return this.itemsPerPage;
    }

    return parseInt(pageSize);
  }
}
