<h1 mat-dialog-title>Publikation archivieren</h1>
<div mat-dialog-content>Soll diese Publikation wirklich archiviert werden?</div>
<div mat-dialog-content>
  <mat-form-field class="w-full">
    <mat-label>Bemerkung zur Archivierung</mat-label>
    <input  matInput [(ngModel)]="data.archiveRemark">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <button mat-button [mat-dialog-close]="data.archiveRemark" cdkFocusInitial>Ja</button>
</div>
