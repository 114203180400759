<h1 mat-dialog-title>Agentur löschen</h1>
<div mat-dialog-content>Soll diese Agentur wirklich gelöscht werden?</div>
<div mat-dialog-content>
  {{data.agency.name}}
  {{data.agency.firstName}}
  {{data.agency.lastName}}
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
</div>
