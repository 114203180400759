import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private cacheableUrls: string[] = [
    '/api/notes',
    '/api/contacts',
    '/api/departments',
    '/api/states',
    '/api/divisions',
    '/api/agencies',
    '/api/publication_archives',
    '/assets/config',
  ];

  constructor(private cacheService: CacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isCacheable(req)) {
      return next.handle(req);
    }

    const cachedResponse = this.cacheService.get(req.url);
    if (cachedResponse) {
      return of(cachedResponse);
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cacheService.set(req.url, event);
        }
      })
    );
  }

  private isCacheable(req: HttpRequest<any>): boolean {
    if (['POST', 'DELETE', 'PUT'].includes(req.method)){
      this.removeFromCache(req.url)
      return false;
    }

    return (
      req.method === 'GET' &&
      this.cacheableUrls.some(url => req.url.includes(url))
    );
  }

  private removeFromCache(reqUrl: string){
      this.cacheableUrls.forEach((url) => {
        if(reqUrl.includes(url)){
          this.cacheService.clear(url)
        }
      })
  }
}
