import { Action, createReducer, on } from '@ngrx/store';
import { Publication } from 'src/app/entities/publication.entity';
import * as PublicationDetailActions from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.actions';

export const publicationDetailFeatureKey = 'publicationDetail';

export interface PublicationDetailState {
  publicationToEdit: Publication,
  publicationDirty: boolean,
  saving: boolean
}

export const initialState: PublicationDetailState = {
  publicationToEdit: {} as Publication,
  publicationDirty: false,
  saving: false
};

export const publicationDetailReducer = createReducer(
  initialState,
  on(PublicationDetailActions.setPublicationToEdit, (state, publicationToEdit) => {
    return {
      ...state,
      publicationToEdit: publicationToEdit.publicationToEdit
    }
  }),
  on(PublicationDetailActions.setPublicationDirty, (state, isDirty) => {
    return {
      ...state,
      publicationDirty: isDirty.isDirty
    }
  }),
  on(PublicationDetailActions.setPublicationSaving, (state, saving) => {
    return {
      ...state,
      saving: saving.saving
    }
  })
);
