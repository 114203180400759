import { Component, Inject } from '@angular/core';
import { Contact } from 'src/app/entities/contact.entity';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DeleteContactDialogData {
  contact: Contact
}

@Component({
  selector: 'app-delete-contact-dialog',
  templateUrl: './delete-contact-dialog.component.html',
})
export class DeleteContactDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteContactDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
