<div class="mat-elevation-z2">
  <table mat-table #notesTable [dataSource]="dataSource">
    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef ></th>
      <td class="w-32" mat-cell *matCellDef="let row">
        <mat-icon class="black-icon" (click)="deleteNote(row)">delete</mat-icon>
        <mat-icon class="black-icon" (click)="editNote(row)">edit</mat-icon>

        <mat-icon [ngClass]="{'red-icon': row.isPinned}"
                  class="black-icon" (click)="pinnedNoteChange(row)"
                  matTooltip="Notiz oben fixieren"
        >
          push_pin</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef
     >Datum</th>
      <td class="w-24" mat-cell *matCellDef="let row"
          matTooltip="Letzte Änderung"> {{row.updatedAt| date: 'dd.MM.yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="changeUser">
      <th mat-header-cell *matHeaderCellDef >Benutzer</th>
      <td class="w-32"  mat-cell *matCellDef="let row" style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:100px;">
        <ng-container *ngIf="row.changeUser|userFromIri|async; let user">
          {{user.initials}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef >Notiz</th>
      <td mat-cell *matCellDef="let row"> {{row.note}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator #paginatorNotes (page)="itemsPerPages($event)"
                 [length]="dataMeta.totalItems"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 aria-label="Select page of notes">
  </mat-paginator>
</div>

