import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-archive-view-row',
  templateUrl: './archive-view-row.component.html',
  styleUrls: ['./archive-view-row.component.scss']
})
export class ArchiveViewRowComponent implements OnChanges {
  @Input() label = '';
  @Input() text: any;
  @Input() asIcon = false;

  public value: any;

  public ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.text === 'boolean') {
      if (this.text) {
        this.value = 'Ja';
      } else {
        this.value = 'Nein';
      }
    } else {
      this.value = this.text;
    }
  }


}
