import { Component } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { MatTableDataSource } from '@angular/material/table';
import { Agency } from 'src/app/entities/agency.entity';
import { Store } from '@ngrx/store';
import { AgencyAdminState } from 'src/app/modules/administration/state/agency-admin.reducer';
import { setAgencyToEdit } from 'src/app/modules/administration/state/agency-admin.actions';
import { NotifyService } from 'src/app/services/notify.service';
import {
  DeleteAgencyDialogComponent, DeleteAgencyDialogData
} from 'src/app/modules/administration/dialogs/delete-agency-dialog/delete-agency-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-agency-admin',
  templateUrl: './agency-admin.component.html',
  styleUrls: ['./agency-admin.component.scss'],

})
export class AgencyAdminComponent {
  public agencyService: EntityCollectionService<Agency>;

  public dataSource = new MatTableDataSource<Agency>();

  public displayedColumns: string[] = [
    'isActive',
    'name',
    'salutation',
    'firstName',
    'lastName',
    'street',
    'zipCode',
    'city',
    'email',
    'phoneNumber',
    'tools'
  ];

  constructor(private entityServices: EntityServices,
              private agencyAdminState: Store<AgencyAdminState>,
              private notifyService: NotifyService,
              public dialog: MatDialog,
  ) {
    this.agencyService = this.entityServices.getEntityCollectionService('Agency');
    this.agencyService.getAll();
    this.agencyService.entities$.subscribe((agencies) => {
      this.dataSource.data = agencies
    })

  }

  public setAgencyToEdit(agency?: Agency) {
    if(agency){
      this.agencyAdminState.dispatch(setAgencyToEdit({agencyToEdit: agency}))
    } else {
      this.agencyAdminState.dispatch(setAgencyToEdit({agencyToEdit: {} as Agency}))
    }
  }

  public toggleAgencyActive(agency: Agency) {
    agency.isActive = !agency.isActive;

    this.agencyService.update(agency).subscribe((agency) => {
      this.notifyService.toast('success', 'Status der Agentur '+ (agency?.name || '') +' geändert')
    })
  }

  public confirmDelete(agency: Agency ) {
    const dialogRef = this.dialog.open(DeleteAgencyDialogComponent, {
      data: {agency} as DeleteAgencyDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agencyService.delete(agency).subscribe(() => {
          this.notifyService.toast('success', 'Agentur wurde gelöscht');
        })
      } else {
        this.notifyService.toast('info', 'Löschen abgebrochen');
      }
    });
  }
}
