
<ng-container *ngIf="searchMode">
  <mat-toolbar>
    <a mat-button (click)="clearSearch()">
      <mat-icon color="primary">cancel</mat-icon>
      Suche löschen
    </a>
  </mat-toolbar>
</ng-container>
<table appScrollNearEnd (nearEnd)="onNearEndScroll()"
       matSort (matSortChange)="sortData($event)"
       mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
  <ng-container matColumnDef="priority">
    <th mat-sort-header="priority" *matHeaderCellDef>Prio.</th>
    <td mat-cell *matCellDef="let element">
      <div>
        <app-prio-indicator [prio]="element.priority"></app-prio-indicator>
      </div>
      <div>
        <a
          (click)="publicationSelected(element)"><mat-icon class="black-icon">edit</mat-icon></a>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="orderNrNew">
    <th mat-sort-header="orderNrNew" *matHeaderCellDef>Best.-Nr.</th>
    <td mat-cell *matCellDef="let element">
      {{element?.orderNrNew}}
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-sort-header="title" *matHeaderCellDef>Titel</th>
    <td mat-cell *matCellDef="let element">{{element.title}}</td>
  </ng-container>

  <ng-container matColumnDef="state">
    <th mat-sort-header="state.name" *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.state|stateFromIri|async; let state" >
        {{state.name}}
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="note">
    <th mat-sort-header="note" *matHeaderCellDef>Notiz</th>
    <td mat-cell *matCellDef="let element">
      {{element.latestNote}}
    </td>
  </ng-container>

  <ng-container matColumnDef="supervisorKom">
    <th mat-sort-header="supervisorKom.firstName" *matHeaderCellDef>Betreuer KOM</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.supervisorKom|contactFromIri|async; let contact">
        {{contact.firstName}} {{contact.lastName}}
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="dguvSupervisor">
    <th mat-sort-header="dguvSupervisor.firstName" *matHeaderCellDef>DGUV Betreuer</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.dguvSupervisor|contactFromIri|async; let contact">
       {{contact.firstName}} {{contact.lastName}}
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [id]="row.id"
      [ngClass]="{'highlighted': listViewService.selectedPublication.id == row.id}" ></tr>
</table>
