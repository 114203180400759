import { Pipe, PipeTransform } from '@angular/core';
import { DefaultPluralizer } from '@ngrx/data';

@Pipe({
  name: 'iriPluralize'
})
export class IriPluralizePipe extends DefaultPluralizer implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return this.pluralize(value).toLowerCase();
  }

}
