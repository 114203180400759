import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IdFromIriPipe } from 'src/app/pipes/id-from-iri.pipe';
import { ContactFromIriPipe } from 'src/app/pipes/resource-from-iri.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgencyFromIriPipe } from 'src/app/pipes/agency-from-iri.pipe';
import { PrioButtonRowComponent } from 'src/app/components/prio-button-row/prio-button-row.component';
import { IriPluralizePipe } from 'src/app/pipes/iri-pluralize.pipe';
import { SelectComponent } from 'src/app/components/select/select.component';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LatestPublicationNotePipe } from 'src/app/pipes/latest-publication-note.pipe';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollNearEndDirective } from 'src/app/directive/scroll-near-end.directive';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    IdFromIriPipe,
    ContactFromIriPipe,
    AgencyFromIriPipe,
    PrioButtonRowComponent,
    IriPluralizePipe,
    SelectComponent,
    LatestPublicationNotePipe,
    ScrollNearEndDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatRippleModule,
    MatChipsModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatProgressBarModule,


  ],
  providers:[

  ],
  exports: [
    CommonModule,
    IriPluralizePipe,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    IdFromIriPipe,
    ContactFromIriPipe,
    AgencyFromIriPipe,
    MatDatepickerModule,
    MatRippleModule,
    MatChipsModule,
    MatDialogModule,
    MatTooltipModule,
    PrioButtonRowComponent,
    SelectComponent,
    MatButtonToggleModule,
    MatProgressBarModule,
    LatestPublicationNotePipe,
    ScrollNearEndDirective
  ]
})
export class MaterialSharedModule { }
