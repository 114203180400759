import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgencyAdminState } from 'src/app/modules/administration/state/agency-admin.reducer';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { EntityServices } from '@ngrx/data';
import { NotifyService } from 'src/app/services/notify.service';
import { PublicationStateService } from 'src/app/entities/services/publication-state.service';
import { PublicationState } from 'src/app/entities/publication-state.entity';
import { Subscription } from 'rxjs';
import { StateChangeTaskService } from 'src/app/entities/services/state-change-task.service';
import { StateChangeTask } from 'src/app/entities/state-change-task.entity';
import { RenderMailConfig } from 'src/app/models/render-mail-config.model';
import { ConfigService } from 'src/app/services/config.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-state-tasks-admin-form',
  templateUrl: './state-tasks-admin-form.component.html',
  styleUrls: ['./state-tasks-admin-form.component.scss']
})
export class StateTasksAdminFormComponent implements OnDestroy, AfterViewInit {


  public formGroup = this.fb.group({
    publicationState: [''],
    name: [''],
    tasks: this.fb.group({
      renderMail: this.fb.group({
        config: this.fb.group({
          templateFile: [''],
          receiver: [''],
          subject: [''],
        })
      }),
      writeNote: this.fb.group({

        config: ['']
      })
    }),

  });
  public renderMailSelected = false;
  public writeNoteSelected = false;

  private selectedStateId?: number;
  public stateToEdit?: PublicationState;
  private sub: Subscription | undefined;
  private tasks: StateChangeTask[] = [];

  constructor(
    private agencyAdminState: Store<AgencyAdminState>,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private entityServices: EntityServices,
    private notifyService: NotifyService,
    private publicationStateService: PublicationStateService,
    private stateTaskService: StateChangeTaskService,
    private configService: ConfigService,
  ) {

  }

  public ngAfterViewInit(): void {
    this.selectedStateId = Number(this.route.snapshot.paramMap.get('contactId'));

    if (typeof this.selectedStateId !== 'undefined') {
      if (this.selectedStateId !== 0) {
        this.stateTaskService.getTasksByStateId(this.selectedStateId).subscribe((tasks) => {
          if (tasks) {
            this.tasks = tasks;
            this.initForm(tasks);
          }
        });
        this.sub = this.publicationStateService.entities$.subscribe((entities) => {
          this.stateToEdit = entities.find((entity) => entity.id === this.selectedStateId);

          if (typeof this.stateToEdit === 'undefined') {
            this.publicationStateService.getByKey(this.selectedStateId);
          }
        });
      }
    }
  }

  private initForm(tasks: StateChangeTask[]) {
    this.formGroup.controls.tasks.controls.writeNote.disable();
    this.formGroup.controls.tasks.controls.renderMail.disable();
    tasks.forEach((task) => {
      if (task.taskName === 'Notiz anlegen') {
        this.writeNoteSelected = true;
        this.formGroup.controls.tasks.controls.writeNote.enable();
      }

      if (task.taskName === 'Render E-Mail Body') {
        const config: RenderMailConfig = JSON.parse(task.config);
        this.renderMailSelected = true;
        this.formGroup.controls.tasks.controls.renderMail.enable();
        this.formGroup.controls.tasks.controls.renderMail.controls.config.controls.subject.setValue(config.subject);
        this.formGroup.controls.tasks.controls.renderMail.controls.config.controls.receiver.setValue(config.receiver);
        this.formGroup.controls.tasks.controls.renderMail.controls.config.controls.templateFile.setValue(config.template);
      }

    });
  }

  public writeNoteTaskToggle($event: MatCheckboxChange) {
    if ($event.checked) {
      this.formGroup.controls.tasks.controls.writeNote.enable();
    } else {
      this.formGroup.controls.tasks.controls.writeNote.disable();
    }
  }

  public renderMailTaskToggle($event: MatCheckboxChange) {
    if ($event.checked) {
      this.formGroup.controls.tasks.controls.renderMail.enable();
    } else {
      this.formGroup.controls.tasks.controls.renderMail.disable();
    }
  }

  public save() {
    const statesApiUrl = this.configService.config.statesUrl;
    if (this.stateToEdit) {
      this.saveRenderMailTask(this.stateToEdit, statesApiUrl);
      this.saveWriteNoteTask(this.stateToEdit, statesApiUrl);
      this.notifyService.toast('success', 'Status Task-Konfiguration gespeichert');

    } else {
      this.notifyService.toast('error', 'Kein Status für Status Task geladen');
    }
  }

  private saveRenderMailTask(stateToEdit: PublicationState, statesApiUrl: string): void {
    const existingTaskConfig = this.tasks.find((task) => task.taskName === 'Render E-Mail Body');

    if (this.renderMailSelected) {
      const config = JSON.stringify({
        receiver: this.formGroup.controls.tasks.controls.renderMail.controls.config.controls.receiver.value,
        subject: this.formGroup.controls.tasks.controls.renderMail.controls.config.controls.subject.value,
        template: this.formGroup.controls.tasks.controls.renderMail.controls.config.controls.templateFile.value,
      } as RenderMailConfig);

      if (existingTaskConfig) {

        existingTaskConfig.config = config;
        this.updateExistingConfig(existingTaskConfig);
      } else {
        const newTaskConfig = {
          taskName: 'Render E-Mail Body',
          config: config,
          state: statesApiUrl + '/' + stateToEdit.id
        } as StateChangeTask;

        this.saveNewStateChangeTaskConfig(newTaskConfig);
      }
    } else {
      if (existingTaskConfig) {
        this.removeExistingConfig(existingTaskConfig);
      }
    }
  }

  private saveWriteNoteTask(stateToEdit: PublicationState, statesApiUrl: string) {
    const existingTaskConfig = this.tasks.find((task) => task.taskName === 'Notiz anlegen');

    if (this.writeNoteSelected) {
      if (typeof existingTaskConfig === 'undefined') {
        const newTaskConfig = {
          taskName: 'Notiz anlegen',
          state: statesApiUrl + '/' + stateToEdit.id
        } as StateChangeTask;

        this.saveNewStateChangeTaskConfig(newTaskConfig);
      }
    } else {
      if (existingTaskConfig) {
        this.removeExistingConfig(existingTaskConfig);
      }
    }
  }

  private updateExistingConfig(stateTask: StateChangeTask) {
    this.stateTaskService.update(stateTask);
  }

  private removeExistingConfig(stateTask: StateChangeTask) {
    this.stateTaskService.delete(stateTask);
  }

  private saveNewStateChangeTaskConfig(stateTask: StateChangeTask) {
    this.stateTaskService.add(stateTask);
  }

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

  }
}
