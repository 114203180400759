import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import { getUserLoggedInState } from 'src/app/state/app-user-state/app-user.selectors';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavService } from 'src/app/services/side-nav.service';
import { ListViewService } from 'src/app/modules/list-view/list-view.service';
import { JsonApiTypeMeta } from 'src/app/models/JsonApiTypeResponse';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';
  public loggedIn$ = new Observable<boolean>();
  public sideNavOpened = true;
  public events: any;

  public contextMenuIsHidden = true;

  @ViewChild('sidePanel', { static: true }) sidePanel!: MatSidenav;
  @ViewChild('content', {read: ViewContainerRef}) vcr!: ViewContainerRef;
  @ViewChild('toolsContent', {read: ViewContainerRef}) toolsVcr!: ViewContainerRef;
  constructor(private appUserState: Store<AppUserState>,
              private cd: ChangeDetectorRef,
              public sideNavService: SideNavService,
              public listViewService: ListViewService,
              public dataService:DataService
              ) {
  }

  public ngOnInit(): void {
    this.loggedIn$ = this.appUserState.pipe(select(getUserLoggedInState));
  }

  ngAfterViewInit() {
    // Forcing change detection to fix ExpressionChangedAfterItHasBeenCheckedError
    // https://indepth.dev/posts/1001/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error#forcing-change-detection
    this.cd.detectChanges();
    this.sideNavService.setContentVcr(this.toolsVcr);
  }
  private createView(template: TemplateRef<any>) {
    this.vcr.clear();
    this.vcr.createEmbeddedView(template);
  }

  open(template: TemplateRef<any>) {
    this.createView(template);
    this.contextMenuIsHidden = false;
  }

  close() {
    return this.vcr.clear();
  }

  public openSearch(searchMenuRef: HTMLDivElement) {
      searchMenuRef.classList.add('show');
  }
  public closeSearch(searchMenuRef: HTMLDivElement){
      searchMenuRef.classList.remove('show');

  }

  public setMeta($event: JsonApiTypeMeta) {
    this.listViewService.dataMeta$.next($event);
    this.listViewService.resetPagination();
  }


}
