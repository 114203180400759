import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config = {} as Config;

  constructor(private http: HttpClient) {}

  async loadConfig(configPath: string): Promise<void> {
    this.config = await lastValueFrom(this.http.get<Config>(configPath));
  }
}
