import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PublicationDetailState
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.reducer';

export const getPublicationDetailState = createFeatureSelector<PublicationDetailState>(
  'publicationDetailState',
);

export const getPublicationDataDirtyState = createSelector(
  getPublicationDetailState,
  (state) => state.publicationDirty,
);

export const getPublicationDataSavingState = createSelector(
  getPublicationDetailState,
  (state) => state.saving,
);

export const getPublicationToEdit = createSelector(
  getPublicationDetailState,
  (state) => state.publicationToEdit,
);

