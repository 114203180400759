<div class="flex mt-4 ml-2">
  <div>
    <div>
      <mat-toolbar>
        <button mat-button (click)="addDepartment()">
          <mat-icon color="primary">add_circle</mat-icon>
          Referat hinzufügen
        </button>
      </mat-toolbar>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>
              <mat-icon class="black-icon" (click)="departmentToEdit = element; inputIsDisabled = false">edit</mat-icon>
            </div>
            <div>
              <mat-icon class="black-icon" (click)="confirmDelete(element)">delete</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div>
    <div class="ml-2.5">
      <mat-form-field >
        <mat-label>Referat</mat-label>
        <input matInput [disabled]="inputIsDisabled" [(ngModel)]="departmentToEdit.name"
               name="newDepartment"
               [cdkTrapFocusAutoCapture]="!inputIsDisabled"
               [cdkTrapFocus]="!inputIsDisabled"
        >
      </mat-form-field>
      <button mat-raised-button [disabled]="inputIsDisabled" color="primary" (click)="save()">Speichern</button>
    </div>

  </div>
</div>

