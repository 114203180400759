import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { resultMemoize } from '@ngrx/store';

@Component({
  selector: 'app-mail-to',
  templateUrl: './mail-to.component.html',
  styles: ['a { height: 53px !important; } mat-icon { margin-left: 0px !important; margin-right: 0px !important; }']
})
export class MailToComponent implements OnChanges {
  @Input() contactUrl = '';
  @Input() subject = '';

  constructor(private dataService: DataService) {

  }

  public ngOnChanges(changes: SimpleChanges): void {
  }

  public getResource(url: string) {
    this.dataService.makeGetCall<any>(url).subscribe((result) => {
      window.location.href = "mailto:" + result.email + "?subject=" + this.subject;
    });
  }

}
