<div class="flex p-2 h-full min-h-full" >
  <div class="align-middle justify-center acm-w-full max-w-lg relative" >
    <div class="w-full">
      <div class="flex ">
          <h1 class="text-dguv-blue">Login</h1>
      </div>

      <div>
        <form
          (ngSubmit)="submit()"
          name="login"
          [formGroup]="loginForm"
        >
          <div class="grid-rows-1">
            <div>
              <mat-form-field class="w-full">
                <mat-label>Username</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="username"
                  [cdkTrapFocusAutoCapture]="true"
                  [cdkTrapFocus]="true"
                />
                <mat-icon matSuffix> account_circle</mat-icon>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="w-full">
                <mat-label>Password</mat-label>
                <input
                  matInput
                  type="password"
                  formControlName="password"
                />
                <mat-icon matSuffix> vpn_key</mat-icon>
              </mat-form-field>
            </div>
            <div>
              <button mat-raised-button type="submit" color="primary" class="w-full">
                <mat-icon> arrow_forward</mat-icon>
                <span>Login</span>
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
