import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from "../../services/config.service";
import { DataService } from "../../services/data.service";
import { NotifyService } from "../../services/notify.service";
import { ThemePalette } from "@angular/material/core";
import { interval, Observable, Subject, takeUntil, switchMap, tap } from 'rxjs';

export interface ShopSyncApiResponse {
  success: boolean,
  statusCode: string
}

@Component({
  selector: 'app-sync-all-publication-button',
  templateUrl: './sync-all-publication-button.component.html',
  styleUrls: ['./sync-all-publication-button.component.scss']
})
export class SyncAllPublicationButtonComponent implements OnInit, OnDestroy {
  disabled = false;
  color: ThemePalette = 'primary';
  tooltip = 'Publikationen aktualisieren';
  private destroy$ = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private dataService: DataService,
    private notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    this.startContinuousStatusCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onClick() {
    this.triggerSync();
  }

  private startContinuousStatusCheck() {
    interval(10000) // Prüfe alle 10 Sekunden
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.checkSyncStatus())
      )
      .subscribe();
  }

  private triggerSync() {
    let apiUrl = this.getApiUrl();
    this.dataService.makeGetCall<ShopSyncApiResponse>(apiUrl)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          if (response.statusCode == 'SYNC_COMPLETED_SUCCESSFULLY') {
            this.notifyService.toast('success', 'Publikation wurden erfolgreich aktualisiert');
          } else if (response.statusCode == 'INTERNAL_SERVER_ERROR') {
            this.notifyService.toast('error', 'Beim Aktualisieren der Publikation ist ein Fehler aufgetreten');
          }
          // SYNC_ALREADY_IN_PROGRESS wird durch das kontinuierliche Prüfen abgedeckt
        }
      );
  }

  private checkSyncStatus(): Observable<ShopSyncApiResponse> {
    let apiUrl = this.configService.config.apiUrl + this.configService.config.publicationSyncStatusUrl;
    return this.dataService.makeGetCall<ShopSyncApiResponse>(apiUrl).pipe(
      tap(response => {
        if (response.statusCode == 'SYNC_IN_PROGRESS') {
          this.disableButton();
        } else if (response.statusCode == 'SYNC_NOT_IN_PROGRESS') {
          this.enableButton();
        }
      })
    );
  }

  private disableButton() {
    this.disabled = true;
    this.color = 'accent';
    this.tooltip = 'Publikationen werden aktualisiert ...';
  }

  private enableButton() {
    this.disabled = false;
    this.color = 'primary';
    this.tooltip = 'Publikationen aktualisieren';
  }

  private getApiUrl(): string {
    return this.configService.config.apiUrl + this.configService.config.publicationSyncUrl;
  }
}
