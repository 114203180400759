import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  Agency: {},
  AgencyType: {},
  Contact: {},
  Publication: {},
  Department: {},
  Division: {},
  Note: {},
  PublicationArchive: {},
  ContactType: {},
  User: {},
  PublicationState: {},
  StateChange: {},
  StateChangeTask: {},
};

const pluralNames = {};

export const entityConfig = {
  entityMetadata,
  pluralNames
};
