import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { interval, Observable, startWith, switchMap, tap } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { Publication } from 'src/app/entities/publication.entity';

@Injectable({
  providedIn: 'root'
})
export class DataPollingService {
  private apiUrl = '';
  private lastTimestamp = new Date().toISOString();

  constructor(private dataService: DataService,
              private configService: ConfigService) {
    this.apiUrl = this.configService.config.apiUrl + this.configService.config.publicationUrl
  }

  pollData(): Observable<any> {
    return interval(10000).pipe(
      startWith(0),
      switchMap(() => this.fetchDataSinceLastTimestamp())
    );
  }

  private fetchDataSinceLastTimestamp(): Observable<any> {
    const url = `${this.apiUrl}?updatedAt[strictly_after]=${this.lastTimestamp}`;
    return this.dataService.makeGetCall<Publication[]>(url).pipe(
      tap((response) => {
        if (response && response.length > 0) {
          const latestTimestamp = Math.max(...response.map(item => new Date(item.updatedAt).getTime()));
          this.lastTimestamp = new Date(latestTimestamp).toISOString();
        }
      })
    );
  }
}
