{
  "apiUrl": "https://wfdb-api.dev.publikationen.dguv.de",
  "authentication": {
    "api": "/api/login_check",
    "tokeRefreshUrl": "/api/token/refresh",
    "token": {
      "lifeCycle": 60000
    }
  },
  "publicationArchiveUrl": "/api/publication_archives",
  "publicationUrl": "/api/publications",
  "publicationRefreshUrl": "/shop-sync/article",
  "publicationSyncUrl": "/shop-sync/all",
  "publicationSyncStatusUrl": "/shop-sync/status",
  "notesUrl": "/api/notes",
  "statesUrl": "/api/states",
  "stateChangeTasks": {
    "renderEmailBody": "Render E-Mail Body",
    "noteWriter": "Notiz anlegen"
  }
}
