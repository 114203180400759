import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListViewComponent } from 'src/app/modules/list-view/components/list-view/list-view.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import {
  DepartmentAdminComponent
} from 'src/app/modules/administration/components/department-admin/department-admin.component';
import { AgencyAdminComponent } from 'src/app/modules/administration/components/agency-admin/agency-admin.component';
import {
  AgencyAdminFormComponent
} from 'src/app/modules/administration/components/agency-admin-form/agency-admin-form.component';
import {
  AbteilungAdminComponent
} from 'src/app/modules/administration/components/abteilung-admin/abteilung-admin.component';
import { ContactAdminComponent } from 'src/app/modules/administration/components/contact-admin/contact-admin.component';
import {
  ContactAdminFormComponent
} from 'src/app/modules/administration/components/contact-admin-form/contact-admin-form.component';
import {
  ArchiveViewComponent
} from 'src/app/modules/publication-detail/components/archive-view/archive-view.component';
import { DetailSearchComponent } from 'src/app/modules/detail-search/components/detail-search/detail-search.component';
import {
  StateTasksAdminComponent
} from 'src/app/modules/administration/components/state-tasks-admin/state-tasks-admin.component';
import {
  StateTasksAdminFormComponent
} from 'src/app/modules/administration/components/state-tasks-admin-form/state-tasks-admin-form.component';
import {
  ExportPublicationsComponent
} from 'src/app/modules/administration/components/export-publications/export-publications.component';
import { ExcelImportComponent } from 'src/app/modules/administration/components/excel-import/excel-import.component';


const routes: Routes = [
  {
    path: 'list',
    component: ListViewComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_USER', 'ROLE_ADMIN']},
  }, {
    path: 'archive/:archiveId',
    component: ArchiveViewComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_USER', 'ROLE_ADMIN']},
  }, {
    path: 'search',
    component: DetailSearchComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_USER', 'ROLE_ADMIN']},
  }, {
    path: 'admin/department',
    component: DepartmentAdminComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/division',
    component: AbteilungAdminComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/contact',
    component: ContactAdminComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/contact/edit',
    component: ContactAdminFormComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/contact/edit/:contactId',
    component: ContactAdminFormComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/agency',
    component: AgencyAdminComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/agency/edit',
    component: AgencyAdminFormComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/agency/add',
    component: AgencyAdminFormComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/state-tasks',
    component: StateTasksAdminComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/state-tasks/edit/:contactId',
    component: StateTasksAdminFormComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/export/download/:fileName',
    component: ExportPublicationsComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  }, {
    path: 'admin/export',
    component: ExportPublicationsComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  },{
    path: 'admin/import',
    component: ExcelImportComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ROLE_ADMIN']},
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
