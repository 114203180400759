import { Pipe, PipeTransform } from '@angular/core';
import { ContactTypeService } from 'src/app/entities/services/contact-type.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContactType } from 'src/app/entities/contact-type.entity';
import { StateChangeTaskService } from 'src/app/entities/services/state-change-task.service';
import { StateChangeTask } from 'src/app/entities/state-change-task.entity';

@Pipe({
  name: 'tasksOfStateByIri'
})
export class TasksOfStateByIriPipe implements PipeTransform {

  constructor(public stateChangeTaskService:StateChangeTaskService) {
  }
  transform(id: number): Observable<StateChangeTask[]|undefined> {
    return this.stateChangeTaskService.getTasksByStateId(id);
  }

}
