<ng-toast-stack></ng-toast-stack>
<ng-container *ngIf="(loggedIn$|async) === false">
  <div class="absolute">
    <img alt="dguv banner" ngSrc="assets/img/Logo_DGUV_RGB_1z_150px.png" width="471" height="150">
  </div>
  <div class="h-full min-h-full">
    <app-login></app-login>
  </div>
</ng-container>

<ng-container *ngIf="(loggedIn$|async) === true" class="relative">
  <app-toolbar [sidenav]="sidenav" (menuToggle)="sidenav.toggle()"></app-toolbar>
  <mat-progress-bar class="fixed inset-x-0 top-0 h-16 z-50" *ngIf="dataService.isLoading" mode="indeterminate"></mat-progress-bar>
  <mat-sidenav-container class="sidenav-container custom-drawer" [hasBackdrop]="false" [autosize]="true">

    <mat-sidenav class="sidenav" #sidenav mode="side" [(opened)]="sideNavOpened">
      <div class="flex min-h-full">
        <div class="pr-1">
          <div class="mt-4">
            <a mat-icon-button [routerLink]=" '/list'" class="icon-button-large" (click)="sideNavService.setTools(contextToolsMenu); contextMenuIsHidden=true"
               matTooltip="Publikationen" [matTooltipPosition]="'right'">
              <mat-icon color="primary">view_list</mat-icon>
            </a>
          </div>
          <div class="mb-6 mt-4">
            <button mat-icon-button color="primary" [routerLink]=" '/list'" class="icon-button-large" (click)="openSearch(searchMenuRef)"
                    matTooltip="Detail-Suche" [matTooltipPosition]="'right'">
              <mat-icon color="primary" >search</mat-icon>
            </button>
          </div>
          <div class=" my-6">
          <app-sync-all-publication-button></app-sync-all-publication-button>
          </div>
          <div class=" my-6">
            <button mat-icon-button color="primary" class="icon-button-large" (click)="open(temp2Ref)"
                    matTooltip="Administration" [matTooltipPosition]="'right'">
              <mat-icon color="primary">settings</mat-icon>
            </button>
          </div>
          <div>
            <ng-container #toolsContent></ng-container>
          </div>
        </div>
        <div [hidden]="contextMenuIsHidden"   class="mat-elevation-z4">
          <div class="flex justify-between min-h-full">
            <div>
               <ng-container #content></ng-container>
            </div>
            <div>
              <button mat-icon-button (click)="contextMenuIsHidden = true">
                <mat-icon>keyboard_double_arrow_left</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
    <!-- this is the sidenav we are concerned with -->
  </mat-sidenav-container>
  <div id="detailSearchForm" class="flex items-center justify-center dropdown-content w-full " #searchMenuRef>
      <div>
        <mat-card class="p-2">
          <app-detail-search (closeDialog)=closeSearch(searchMenuRef) (dataMeta)="setMeta($event)"></app-detail-search>
        </mat-card>
      </div>
  </div>
</ng-container>

<ng-template #temp2Ref>
  <div class="m-2">
        <div>
          <h3>Administration</h3>
        </div>
        <div>
          <a mat-button routerLink="admin/department">Referate</a>
        </div>
        <div>
          <a mat-button routerLink="admin/division">Abteilungen</a>
        </div>
        <div>
          <a mat-button routerLink="admin/contact">Betreuer</a>
        </div>
        <div>
          <a mat-button routerLink="admin/agency">Agenturen</a>
        </div>
        <div>
          <a mat-button routerLink="admin/state-tasks">Status Tasks</a>
        </div>
        <div>
          <a mat-button routerLink="admin/export">Export Publikationen</a>
        </div>
        <div>
          <a mat-button routerLink="admin/import">Excel Import</a>
        </div>
  </div>
</ng-template>



<ng-template #contextToolsMenu>
  <div class="m-2">

  </div>
</ng-template>
