import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgencyAdminState } from 'src/app/modules/administration/state/agency-admin.reducer';
import { getAppUserState } from 'src/app/state/app-user-state/app-user.selectors';

export const getAgencyAdminState = createFeatureSelector<AgencyAdminState>(
  'agencyAdminState'
);

export const getAgencyToEdit = createSelector(
  getAgencyAdminState,
  state => state.agencyToEdit
);
