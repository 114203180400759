import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public isLoading = false;
  constructor(private httpClient: HttpClient) { }

  public makePostCall<T>(url: string, data: any): Observable<T> {
    const headers = new HttpHeaders();

    headers.append('content-type', 'application/json');

    return this.httpClient.post<T>(url, data, { headers });
  }

  public makePutCall<T>(url: string, data: any):  Observable<T>  {

    const headers = new HttpHeaders();

    headers.append('content-type', 'application/json');

    return this.httpClient.put<T>(url, data, { headers });
  }

  public makeGetCall<T>(url: string, accept: string = 'application/json'): Observable<T>{
    const headers = new HttpHeaders({
      Accept: accept,
    });

    return this.httpClient.get<T>(url, {headers});
  }

  downloadFile(url: string): Observable<Blob> {
    return this.httpClient.get(url, {
      responseType: 'blob'
    });
  }
}
