import { Component } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { PublicationArchive } from 'src/app/entities/publication-archive.entity';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-archive-view',
  templateUrl: './archive-view.component.html',
  styleUrls: ['./archive-view.component.scss']
})
export class ArchiveViewComponent {
  public publicationArchiveService: EntityCollectionService<PublicationArchive>;

  public archivedPublication$: Observable<PublicationArchive|undefined>|undefined;

  constructor(private entityServices: EntityServices,
              private route: ActivatedRoute,
              private location: Location
              ) {

    const archiveId = Number(this.route.snapshot.paramMap.get('archiveId'));

    this.publicationArchiveService =  this.entityServices.getEntityCollectionService('PublicationArchive');
    this.publicationArchiveService.getByKey(archiveId);

    this.publicationArchiveService.loaded$.subscribe((loaded) => {
      if(loaded === false){
        this.archivedPublication$ = this.publicationArchiveService.getByKey(archiveId);
      } else {
        this.archivedPublication$ = this.publicationArchiveService.entities$.pipe(
          map(archives => archives.find(archive => archive.id === archiveId)),
          first()
        )
      }
    } )

  }
  public goBack(): void {
    this.location.back();
  }
}
