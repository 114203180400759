import { isDevMode } from '@angular/core';
import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { appUserReducer, AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import {
  publicationDetailReducer,
  PublicationDetailState
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.reducer';
import { agencyAdminReducer, AgencyAdminState } from 'src/app/modules/administration/state/agency-admin.reducer';

export interface AppState {
  appUserState: AppUserState,
  publicationDetailState: PublicationDetailState,
  agencyAdminState: AgencyAdminState
}

export const reducers: ActionReducerMap<AppState> = {
  appUserState: appUserReducer,
  publicationDetailState: publicationDetailReducer,
  agencyAdminState: agencyAdminReducer,
};


export const metaReducers: MetaReducer<AppState>[] = isDevMode() ? [] : [];
