<div>
  <mat-toolbar>
    <a mat-button routerLink="add" (click)="setAgencyToEdit()">
      <mat-icon color="primary">add_circle</mat-icon>
      Agentur hinzufügen
    </a>

  </mat-toolbar>

</div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="isActive">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle [(ngModel)]="element.isActive" (toggleChange)="toggleAgencyActive(element)" name="isActive"></mat-slide-toggle>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Agentur Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="salutation">
    <th mat-header-cell *matHeaderCellDef> Anrede </th>
    <td mat-cell *matCellDef="let element"> {{element.salutation}} </td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef> Kontakt Vorname </th>
    <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef> Kontakt Nachname </th>
    <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
  </ng-container>

  <ng-container matColumnDef="street">
    <th mat-header-cell *matHeaderCellDef> Straße und Nr. </th>
    <td mat-cell *matCellDef="let element"> {{element.street}} </td>
  </ng-container>

  <ng-container matColumnDef="zipCode">
    <th mat-header-cell *matHeaderCellDef> PLZ </th>
    <td mat-cell *matCellDef="let element"> {{element.zipCode}} </td>
  </ng-container>

  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef> Ort </th>
    <td mat-cell *matCellDef="let element"> {{element.city}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> E-Mail </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <th mat-header-cell *matHeaderCellDef> Telefon </th>
    <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="tools">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div>
        <div>
          <a routerLink="edit" (click)="setAgencyToEdit(element)">
            <mat-icon class="black-icon">edit</mat-icon>
          </a>
        </div>
        <div>
          <mat-icon class="black-icon" (click)="confirmDelete(element)">delete</mat-icon>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
