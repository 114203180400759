import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Department } from 'src/app/entities/department.entity';

export interface DeleteDepartmentDialogData {
  department: Department
}

@Component({
  selector: 'app-delete-department-dialog',
  templateUrl: './delete-department-dialog.component.html',
})
export class DeleteDepartmentDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteDepartmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDepartmentDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
