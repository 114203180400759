import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { AgencyType } from 'src/app/entities/agency-type.entity';
import { ContactType } from 'src/app/entities/contact-type.entity';

@Injectable({
  providedIn: 'root'
})
export class ContactTypeService extends EntityCollectionServiceBase<ContactType>{

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ContactType', serviceElementsFactory);
  }
}

