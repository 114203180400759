import { isDevMode, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from 'src/app/reducers';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory, MaterialSharedModule } from 'src/app/modules/material.shared.module';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from 'src/app/state/config/entity-metadata';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { NgToastStackModule } from 'ng-toast-stack';
import { LoginComponent } from 'src/app/components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { QuickSearchComponent } from 'src/app/components/quick-search/quick-search.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ListViewModule } from 'src/app/modules/list-view/list-view.module';
import { PublicationDetailModule } from 'src/app/modules/publication-detail/publication-detail.module';
import { NgxPrintModule } from 'ngx-print';
import { A11yModule } from '@angular/cdk/a11y';
import { DetailSearchModule } from 'src/app/modules/detail-search/detail-search.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AdministrationModule } from 'src/app/modules/administration/administration.module';
import { RouterLink, RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  RefreshPublicationButtonComponent
} from 'src/app/components/refresh-publication-button/refresh-publication-button.component';



@NgModule({ declarations: [
        PaginatorComponent,
        QuickSearchComponent,
        LoginComponent,
        RefreshPublicationButtonComponent,
        ToolbarComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        MaterialSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatToolbarModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        NgToastStackModule,
        MatIconModule,
        LoginComponent,
        ToolbarComponent,
        PaginatorComponent,
        QuickSearchComponent,
        RouterLink,
        RouterModule,
        ReactiveFormsModule,
        ListViewModule,
        PublicationDetailModule,
        NgxPrintModule,
        NgOptimizedImage,
        A11yModule,
        DetailSearchModule,
        MatAutocompleteModule,
        RefreshPublicationButtonComponent,
    ], imports: [CommonModule,
        AdministrationModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        EffectsModule.forRoot([]),
        EntityDataModule.forRoot(entityConfig),
        FormsModule,
        MaterialSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatToolbarModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        NgToastStackModule,
        MatIconModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
        RouterLink,
        RouterModule,
        ReactiveFormsModule,
        ListViewModule,
        PublicationDetailModule,
        NgxPrintModule,
        NgOptimizedImage,
        A11yModule,
        DetailSearchModule,
        MatAutocompleteModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule { }
