<form>
  <mat-form-field class="w-full">
    <mat-label>Bestellnummer</mat-label>
    <mat-chip-grid #chipGrid aria-label="Publikationen Schnellsuche">
      <mat-chip-row  *ngFor="let searchValue of searchValues" (removed)="remove(searchValue)"
                     [editable]="true"
      >
          <button matChipRemove [attr.aria-label]="'remove ' + searchValue" >
            <mat-icon>cancel</mat-icon>
          </button>
            {{searchValue}}
      </mat-chip-row>
      <input placeholder="Bestellnummer..." #searchInput [formControl]="formCtrl"
             [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             (matChipInputTokenEnd)="add($event)"/>
    </mat-chip-grid>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let publication of filteredResults$ | async" [value]="publication">
        {{publication.orderNrNew}}|
        {{publication.orderNrOld}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
