import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatChipOption } from '@angular/material/chips';

@Component({
  selector: 'app-prio-button-row',
  templateUrl: './prio-button-row.component.html',
  styleUrls: ['./prio-button-row.component.scss']
})
export class PrioButtonRowComponent implements OnInit, OnChanges {
  @Output() selectedPrio = new EventEmitter<any>();

  @Input() priority = 0;

  public prio1 = '';
  public prio2 = '';
  public prio3 = '';
  public prio4 = '';

  public ngOnInit(): void {
    this.setChipColor(this.priority);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initChips();
    this.setChipColor(this.priority);
  }


  private initChips() {
    this.prio1 = '';
    this.prio2 = '';
    this.prio3 = '';
    this.prio4 = '';
  }

  public setPrio(prio: number, prioRef: MatChipOption) {
    this.initChips();
    this.setChipColor(prio);
    this.selectedPrio.emit(prio);

    if(prioRef.selected === false){
      this.removeColor(prioRef);
      this.initChips();
    }
  }

  private removeColor(prioRef: MatChipOption) {
    prioRef._elementRef.nativeElement
      .classList.remove(
      'red-chip',
      'yellow-chip',
      'green-chip',
      'white-chip'
    );

  }

  private setChipColor(prio: number) {
    switch (prio) {
      case 1: {
        this.prio1 = 'white-chip';
        break;
      }
      case 2: {
        this.prio2 = 'green-chip';
        break;
      }
      case 3: {
        this.prio3 = 'yellow-chip';
        break;
      }
      case 4: {
        this.prio4 = 'red-chip';
        break;
      }
    }
  }
}
