<div class="m-2">
  <div class="flex">
    <div>
      <button mat-raised-button color="primary"
              printSectionId="print-section"
              [useExistingCss]="true"
              ngxPrint>Drucken
      </button>
    </div>
  </div>
</div>

<div id="print-section">
  <ng-container *ngIf="(archivedPublication$|async) as archive;">
    <div class="container align-middle justify-center mt-4">
      <h3 class="underline">Archiv</h3>
      <app-archive-view-row [label]="'Erstellung:'" [text]="archive.createdAt"></app-archive-view-row>
      <ng-container *ngIf="(archive.changeUser|userFromIri|async); let user">
        <app-archive-view-row [label]="'Benutzer:'" [text]="user.firstName+' '+user.lastName"></app-archive-view-row>
      </ng-container>
      <app-archive-view-row [label]="'Bemerkung:'" [text]="archive.archiveRemark"></app-archive-view-row>

      <h3 class="underline" >Zusammenfassung</h3>
      <app-archive-view-row [label]="'Kategoriename:'" [text]="archive.subject"></app-archive-view-row>
      <app-archive-view-row [label]="'Bestellnummer:'" [text]="archive.orderNrNew"></app-archive-view-row>
      <app-archive-view-row [label]="'Best.-Nr. Alt:'" [text]="archive.orderNrOld"></app-archive-view-row>
      <app-archive-view-row [label]="'Akt. Fassung:'" [text]="archive.updatedVersion"></app-archive-view-row>
      <app-archive-view-row [label]="'Titel:'" [text]="archive.title||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Aktiv:'"
                            [text]="archive.isActive"
      >
      </app-archive-view-row>
      <app-archive-view-row [label]="'Kategorie1:'" [text]="archive.category1||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Kategorie2:'" [text]="archive.category2||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Kategorie3:'" [text]="archive.category3||''"></app-archive-view-row>

      <app-archive-view-row [label]="'Ausgabe:'" [text]="archive.edition"></app-archive-view-row>
      <app-archive-view-row [label]="'Status:'" [text]="archive.state"></app-archive-view-row>
      <app-archive-view-row [label]="'Frist:'" [text]="archive.deadline"></app-archive-view-row>
      <app-archive-view-row [label]="'Abteilung:'" [text]="archive.division"></app-archive-view-row>
      <app-archive-view-row [label]="'Referat:'" [text]="archive.department"></app-archive-view-row>
      <app-archive-view-row [label]="'DGUV Betreuer:'" [text]="archive.dguvSupervisor"></app-archive-view-row>
      <app-archive-view-row [label]="'Betreuer KOM:'" [text]="archive.supervisorKom"></app-archive-view-row>

      <app-archive-view-row [label]="'Überprüfung erforderlich am:'"
                            [text]="archive.reviewRequiredOn"></app-archive-view-row>

      <app-archive-view-row [label]="'Anmerkung:'" [text]="archive.note"></app-archive-view-row>
      <app-archive-view-row [label]="'Information zur Schrift (FB/SG Betreuung):'"
                            [text]="archive.scriptInfo"></app-archive-view-row>

      <app-archive-view-row [label]="'Inhalt aktuell:'"
                            [text]="archive.contentIsUpToDate"
      >
      </app-archive-view-row>

      <app-archive-view-row [label]="'Aktuelles CD:'" [text]="archive.isLatestCD">
      </app-archive-view-row>
      <app-archive-view-row [label]="'Bilder in Bilddatenbank:'" [text]="archive.picturesInDb">
      </app-archive-view-row>
      <app-archive-view-row [label]="'Layout:'" [text]="archive.layout"></app-archive-view-row>

      <app-archive-view-row [label]="'Grafiken 3D:'" [text]="archive.graphic3d||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Illustrationen 2D:'" [text]="archive.illustration2d||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Pfadlink:'" [text]="archive.projectFolderPath||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Druckspezifikation:'" [text]="archive.printSpecifications||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Voraussichtliche Lieferung:'" [text]="archive.expectedDelivery||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Copyright:'" [text]="archive.copyright||''"></app-archive-view-row>

      <h3 class="underline" style="page-break-before: always;">Notizen</h3>
      <app-archive-view-note [notes]="archive.notes?.toString()"></app-archive-view-note>

      <h3 class="underline" style="page-break-before: always;">Spezifikation</h3>
      <app-archive-view-row [label]="'Format:'" [text]="archive.format||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Umfang:'" [text]="archive.umfang||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Verarbeitung:'" [text]="archive.verarbeitung||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Extras:'" [text]="archive.extras||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Umschlag:'" [text]="archive.umschlag||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Innenteil:'" [text]="archive.innenteil||''"></app-archive-view-row>

      <h3 class="underline" >Preise/Bestand</h3>
      <app-archive-view-row [label]="'VP Mitglieder:'" [text]="archive.vpMitglieder||''"></app-archive-view-row>
      <app-archive-view-row [label]="'VP Extern:'" [text]="archive.vpExtern||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Bestand:'" [text]="archive.stock||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Auflage/Daten:'" [text]="archive.auflageDaten||''"></app-archive-view-row>

      <h3 class="underline" >Druckanfrage</h3>
      <app-archive-view-row [label]="'Druckereien:'" [text]="archive.druckereien||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Auflagen:'" [text]="archive.auflagen||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Sondertext:'" [text]="archive.sondertext||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Bemerkung:'" [text]="archive.bemerkung||''"></app-archive-view-row>
      <app-archive-view-row [label]="'Auftrag erteilt:'" [text]="archive.auftragErteilt||''"></app-archive-view-row>
    </div>
  </ng-container>
</div>

