import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from 'src/app/modules/material.shared.module';
import {
  DepartmentAdminComponent
} from 'src/app/modules/administration/components/department-admin/department-admin.component';
import { AgencyAdminComponent } from './components/agency-admin/agency-admin.component';
import { FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AgencyAdminFormComponent } from './components/agency-admin-form/agency-admin-form.component';
import { RouterLink } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { AbteilungAdminComponent } from './components/abteilung-admin/abteilung-admin.component';
import { ContactAdminComponent } from './components/contact-admin/contact-admin.component';
import { ContactAdminFormComponent } from './components/contact-admin-form/contact-admin-form.component';
import { ContactTypeFromIriPipe } from './pipes/contact-type-from-iri.pipe';
import { StateTasksAdminComponent } from './components/state-tasks-admin/state-tasks-admin.component';
import { TasksOfStateByIriPipe } from 'src/app/modules/administration/pipes/tasks-of-state-by-iri.pipe';
import { StateTasksAdminFormComponent } from './components/state-tasks-admin-form/state-tasks-admin-form.component';
import { DeleteContactDialogComponent } from './dialogs/delete-contact-dialog/delete-contact-dialog.component';
import {
  DeleteAgencyDialogComponent
} from 'src/app/modules/administration/dialogs/delete-agency-dialog/delete-agency-dialog.component';
import { DeleteDivisionDialogComponent } from './dialogs/delete-division-dialog/delete-division-dialog.component';
import { DeleteDepartmentDialogComponent } from './dialogs/delete-department-dialog/delete-department-dialog.component';
import {
  ExportPublicationsComponent
} from 'src/app/modules/administration/components/export-publications/export-publications.component';
import { ExcelImportComponent } from 'src/app/modules/administration/components/excel-import/excel-import.component';



@NgModule({
  declarations: [
    DepartmentAdminComponent,
    AgencyAdminComponent,
    AgencyAdminFormComponent,
    AbteilungAdminComponent,
    ContactAdminComponent,
    ContactAdminFormComponent,
    ContactTypeFromIriPipe,
    StateTasksAdminComponent,
    TasksOfStateByIriPipe,
    StateTasksAdminFormComponent,
    DeleteContactDialogComponent,
    DeleteAgencyDialogComponent,
    DeleteDivisionDialogComponent,
    DeleteDepartmentDialogComponent,
    ExportPublicationsComponent,
    ExcelImportComponent
  ],
  imports: [
    CommonModule,
    MaterialSharedModule,
    FormsModule,
    MatToolbarModule,
    RouterLink,
    A11yModule,
  ]
})
export class AdministrationModule { }
