import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Note } from 'src/app/entities/note.entity';
import { first, map } from 'rxjs/operators';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteService extends EntityCollectionServiceBase<Note>{

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Note', serviceElementsFactory);
  }

  public getLatestNoteOfPublication(publicationId: number): Observable<Note>{
    return this.getWithQuery('page=1&itemsPerPage=1&publication.id='+publicationId).pipe(
      first(),
      tap((entity) => {
        if(entity[0])
        this.addOneToCache(entity[0]);
      }),
      map(entity => entity.length > 0 ? entity[0] : {} as Note)
    );
  }
}

